import { SvgContent } from '@app/assets/SvgContent'

interface ProgressCircleProps {
  progress: number
}

export const ProgressCircle = ({ progress }: ProgressCircleProps) => {
  return (
    <div className="absolute -right-[2px] top-0 flex h-12 w-14 p-1">
      {progress === 0 && (
        <object className="h-full w-full object-contain" type="image/svg+xml" data={SvgContent.library.progress0} />
      )}

      {progress > 0 && progress < 10 && (
        <object className="h-full w-full object-contain" type="image/svg+xml" data={SvgContent.library.progress10} />
      )}

      {progress >= 10 && progress < 20 && (
        <object className="h-full w-full object-contain" type="image/svg+xml" data={SvgContent.library.progress20} />
      )}

      {progress >= 20 && progress < 30 && (
        <object className="h-full w-full object-contain" type="image/svg+xml" data={SvgContent.library.progress30} />
      )}

      {progress >= 30 && progress < 40 && (
        <object className="h-full w-full object-contain" type="image/svg+xml" data={SvgContent.library.progress40} />
      )}

      {progress >= 40 && progress < 50 && (
        <object className="h-full w-full object-contain" type="image/svg+xml" data={SvgContent.library.progress50} />
      )}

      {progress >= 50 && progress < 60 && (
        <object className="h-full w-full object-contain" type="image/svg+xml" data={SvgContent.library.progress60} />
      )}

      {progress >= 60 && progress < 70 && (
        <object className="h-full w-full object-contain" type="image/svg+xml" data={SvgContent.library.progress70} />
      )}

      {progress >= 70 && progress < 80 && (
        <object className="h-full w-full object-contain" type="image/svg+xml" data={SvgContent.library.progress80} />
      )}

      {progress >= 80 && progress < 90 && (
        <object className="h-full w-full object-contain" type="image/svg+xml" data={SvgContent.library.progress90} />
      )}

      {progress >= 90 && (
        <object className="h-full w-full object-contain" type="image/svg+xml" data={SvgContent.library.progress100} />
      )}
    </div>
  )
}
