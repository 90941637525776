import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useToggle, useWindowSize } from 'react-use'
import classNames from 'classnames'
import dayjs from 'dayjs'
import { motion, MotionValue, useMotionValue, useTransform } from 'framer-motion'

import { ImageContent } from '@app/assets/ImageContent'
import { SvgContent } from '@app/assets/SvgContent'
import { Button, LoaderWrapper, Text } from '@app/components'
import {
  FlashCardAnswer,
  StudentLibraryImageBackground,
  StudentLibraryRankLevel,
  StudentLibrarySubPackageLevel
} from '@app/data'
import { useTranslation } from '@app/locales'
import { GoBackBtn } from '@app/modules/common'
import { useDashboardMenuHeight } from '@app/modules/common/layout/DashboardLayout/useDashboardMenuHeight'
import { LessonProgressBar } from '@app/modules/lesson/components'
import { getImageUrlFromImageType } from '@app/utils/imageUtils'
import { isMobile, isNativeIOS } from '@app/utils/platformUtils'

import { LevelSwitch } from '../../components/LevelSwitch'
import { useStudentLibraryDetail } from '../../data/useStudentLibraryDetail'
import { getBgColor } from '../../utils/getBgColor'
import { useFavoriteToggleHandler } from '../../utils/useFavoriteToggleHandler'
import { useStartPackageHandler } from '../../utils/useStartPackageHandler'
import { PackageDetailAssignment } from './PackageDetailAssignment'
import { PackageDetailItem } from './PackageDetailItem'

/**
 * Very similar to TaskDetailPage.tsx in terms of design
 */
export const PackageDetailPage = () => {
  const navigate = useNavigate()
  const { t } = useTranslation('library')
  const { id } = useParams()

  const {
    detail: currentDetail,
    error: detailError,
    loading: detailLoading
  } = useStudentLibraryDetail({ skip: !id, variables: { subPackageId: id ?? '' } })

  const rookieLevel = currentDetail?.levels.find((lvl) => lvl.level === StudentLibraryRankLevel.ROOKIE)
  const proLevel = currentDetail?.levels.find((lvl) => lvl.level === StudentLibraryRankLevel.PRO)
  const legendLevel = currentDetail?.levels.find((lvl) => lvl.level === StudentLibraryRankLevel.LEGEND)
  // get the highest enabled level
  const currentLevel = legendLevel?.isEnabled ? legendLevel : proLevel?.isEnabled ? proLevel : rookieLevel

  const [selectedLevel, setSelectedLevel] = useState<StudentLibrarySubPackageLevel | undefined>(
    currentLevel as StudentLibrarySubPackageLevel | undefined
  )

  const [isFavoriteFlag, toggleIsFavoriteFlag] = useToggle(currentDetail?.isFavorite || false)

  const bottomBarHeight = useDashboardMenuHeight()
  const { width: windowWidth } = useWindowSize()
  const widthValue = useMotionValue(windowWidth)
  const top = useTransform(widthValue, [391, 769], [216, 296])
  const height = useTransform(widthValue, [391, 769], [286, 366])

  useEffect(() => {
    widthValue.set(windowWidth)
  }, [windowWidth])

  useEffect(() => {
    if (currentDetail) {
      toggleIsFavoriteFlag(currentDetail.isFavorite)
    }
  }, [currentDetail])

  const handleGoBack = () => {
    navigate(-1) // go back
  }

  const { handleStartPackage } = useStartPackageHandler({ id, currentDetail, currentLevel: selectedLevel })
  const { handleOnFavoriteClick } = useFavoriteToggleHandler({ id, isFavoriteFlag, toggleIsFavoriteFlag })

  const getTimeDescription = () => {
    if (!selectedLevel?.isStarted && selectedLevel?.progress == null) {
      return t('duration') + dayjs.duration(selectedLevel?.estimatedDurationSeconds || 0, 'seconds').humanize()
    } else if (selectedLevel?.progress && selectedLevel?.progress < 100) {
      return t('remains') + dayjs.duration(selectedLevel?.remainingDurationSeconds || 0, 'seconds').humanize()
    } else {
      return t('completedIn') + dayjs.duration(selectedLevel?.finishedDurationSeconds || 0, 'seconds').humanize()
    }
  }

  const imageUrl = getImageUrlFromImageType(currentDetail?.image, 'large')

  const result = selectedLevel?.successMeasure || 0
  const progress = selectedLevel?.progress || 0

  if (currentDetail == null) {
    return null
  }

  return (
    <div
      style={{ paddingBottom: 'env(safe-area-inset-bottom)' }}
      className="relative flex h-full w-full flex-col overflow-y-auto overscroll-y-contain"
    >
      <LoaderWrapper loading={detailLoading} error={!!detailError}>
        <CardBg imageSrc={imageUrl} imageBackground={currentDetail?.imageBackground} height={height} />

        <div
          className={classNames(
            'absolute left-4 z-30 md:left-8 md:top-4',
            isNativeIOS() ? 'top-[env(safe-area-inset-top)]' : isMobile() ? 'top-2' : 'top-4'
          )}
        >
          <GoBackBtn onClick={handleGoBack} />
        </div>

        <motion.div
          style={{
            top,
            boxShadow: '0px -80px 90px 25px rgba(44, 0, 119, 0.80), 0px 1px 1px 0px rgba(255, 255, 255, 0.25) inset'
          }}
          className="absolute z-20 flex h-auto w-full flex-col items-center rounded-t-3xl bg-[#5A23BA] p-4 md:p-8"
        >
          <div className="absolute -top-[120px] z-40 flex w-full flex-col items-center justify-center">
            <div className="line-clamp-1 text-center text-[32px] font-extrabold text-[#FAFAFA]">
              {currentDetail?.name}
            </div>
            <div className="flex flex-row items-center justify-between gap-1 font-[600] text-[#FAFAFA]">
              <object className="h-6 w-6 object-contain" type="image/svg+xml" data={SvgContent.library.clockFilled} />
              {getTimeDescription()}
            </div>

            <div className="mt-3 flex w-full max-w-[360px] items-center justify-center">
              {selectedLevel?.level && (
                <LevelSwitch
                  isRookieEnabled={
                    currentDetail?.levels.find((item) => item.level === StudentLibraryRankLevel.ROOKIE) != null
                  }
                  isProEnabled={
                    currentDetail?.levels.find((item) => item.level === StudentLibraryRankLevel.PRO) != null
                  }
                  isProLocked={
                    currentDetail?.levels.find(
                      (item) => item.level === StudentLibraryRankLevel.PRO && item.isEnabled
                    ) == null
                  }
                  isLegendEnabled={
                    currentDetail?.levels.find((item) => item.level === StudentLibraryRankLevel.LEGEND) != null
                  }
                  isLegendLocked={
                    currentDetail?.levels.find(
                      (item) => item.level === StudentLibraryRankLevel.LEGEND && item.isEnabled
                    ) == null
                  }
                  level={selectedLevel?.level}
                  selectLevel={(level) => {
                    if (level === StudentLibraryRankLevel.LEGEND) {
                      setSelectedLevel(legendLevel as StudentLibrarySubPackageLevel)
                    } else if (level === StudentLibraryRankLevel.PRO) {
                      setSelectedLevel(proLevel as StudentLibrarySubPackageLevel)
                    } else {
                      setSelectedLevel(rookieLevel as StudentLibrarySubPackageLevel)
                    }
                  }}
                />
              )}
            </div>
          </div>

          <div className="flex w-full flex-col">
            <div className="flex w-full flex-row items-center justify-between gap-2 pb-0">
              {/* descriptions */}
              <div className="flex w-full flex-col gap-2">
                <div className="flex flex-row items-center gap-2 font-[600] text-[#FAFAFA]">
                  <object className="h-6 w-6 object-contain" type="image/svg+xml" data={SvgContent.library.bookmark} />
                  {currentDetail?.packageName}
                </div>
                <div className="flex flex-row items-center gap-2 font-[600] text-[#FAFAFA]">
                  <div className="flex h-6 w-6 items-center justify-center">
                    <object
                      className="h-5 w-5 object-center"
                      type="image/svg+xml"
                      data={SvgContent.library.chatBuble}
                    />
                  </div>
                  {selectedLevel?.category.map((item) => t(`category.${item.toString()}`)).join(', ')}
                </div>
              </div>

              {/* Favorite icon */}
              <div
                style={{
                  backgroundImage: `url(${ImageContent.library.favoriteEclipse})`,
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center'
                }}
                className="flex h-14 w-14 cursor-pointer items-center justify-center"
                onClick={handleOnFavoriteClick}
              >
                <motion.img
                  className="h-10 w-10"
                  src={isFavoriteFlag ? ImageContent.library.favoriteHeart : ImageContent.library.favoriteInactive}
                  alt="favoriteEclipse"
                  initial={{ scale: 0.8, opacity: 0 }}
                  animate={{
                    scale: isFavoriteFlag ? 1.2 : 1,
                    opacity: isFavoriteFlag ? 1 : 0.8
                  }}
                  transition={{ type: 'spring', stiffness: 300, damping: 15 }}
                />
              </div>
            </div>

            <div className="flex h-[48px] w-full items-center justify-start gap-4 px-1">
              {selectedLevel?.isStarted && (
                <>
                  <LessonProgressBar
                    progress={selectedLevel?.progress || 0}
                    hideCloudAnimations={true}
                    offsetTop={0}
                    offsetLeft={20}
                  />

                  {progress === 100 && result >= 50 ? (
                    <img
                      className="h-12 w-12 rounded-full"
                      src={
                        result >= 90
                          ? ImageContent.library.starGold
                          : result >= 70
                            ? ImageContent.library.starSilver
                            : ImageContent.library.starBronze
                      }
                      alt="favoriteEclipse"
                    />
                  ) : (
                    <Text className="font-semibold text-white">{selectedLevel?.progress || 0}%</Text>
                  )}
                </>
              )}
            </div>
          </div>

          {/* list of answers */}
          <div className="mt-8 flex h-full w-full flex-col gap-4">
            <div className="grid w-full grid-cols-1 gap-3 md:grid-cols-2 xl:grid-cols-3">
              {selectedLevel?.answers && selectedLevel?.answers?.length > 0
                ? selectedLevel?.answers?.map((answer, index) => {
                    return <PackageDetailItem key={index} item={answer as FlashCardAnswer} />
                  })
                : selectedLevel?.features?.map((feature, index) => {
                    return <PackageDetailAssignment key={index} lf={feature} />
                  })}
            </div>
          </div>

          {/* NOTE: insert a space for the button */}
          <div className="min-h-40 w-full" />
        </motion.div>
      </LoaderWrapper>

      <div
        style={{ bottom: `calc(env(safe-area-inset-bottom) + ${bottomBarHeight}px + 16px)` }}
        className="fixed z-[100] flex w-full items-center justify-center"
      >
        <Button className="w-44" mode="secondary" onClick={handleStartPackage}>
          {selectedLevel?.progress == null
            ? t('start')
            : selectedLevel?.progress === 100
              ? t('startAgain')
              : t('continue')}
        </Button>
      </div>
    </div>
  )
}

const CardBg = ({
  imageSrc,
  imageBackground,
  height
}: {
  imageSrc: string
  imageBackground: StudentLibraryImageBackground
  height: MotionValue<number>
}) => {
  return (
    <motion.div style={{ height }} className="absolute top-0 z-20 w-full">
      <img
        style={{ background: getBgColor(imageBackground) }}
        src={imageSrc}
        className="absolute top-0 h-full w-full object-cover object-center"
      />
    </motion.div>
  )
}
