import LessonRocketLight from './svg/lesson/rocket_light.svg'
import LessonWheelOfFortuneArrow from './svg/lesson/wheel_of_fortune_arrow.svg'
import LibraryBookmarkIcon from './svg/library/bookmark.svg'
import LibraryChatBubleIcon from './svg/library/chat_buble.svg'
import LibraryClockFilledIcon from './svg/library/clock_filled.svg'
import LibraryProgress0 from './svg/library/progress_0.svg'
import LibraryProgress10 from './svg/library/progress_10.svg'
import LibraryProgress20 from './svg/library/progress_20.svg'
import LibraryProgress30 from './svg/library/progress_30.svg'
import LibraryProgress40 from './svg/library/progress_40.svg'
import LibraryProgress50 from './svg/library/progress_50.svg'
import LibraryProgress60 from './svg/library/progress_60.svg'
import LibraryProgress70 from './svg/library/progress_70.svg'
import LibraryProgress80 from './svg/library/progress_80.svg'
import LibraryProgress90 from './svg/library/progress_90.svg'
import LibraryProgress100 from './svg/library/progress_100.svg'
import LoginPinUnderline from './svg/login/pin_underline.svg'
import TasksAlertTriangle from './svg/tasks/alert_triangle.svg'
import TasksYellowCheckmark from './svg/tasks/almost_correct.svg'
import TasksCheckmark from './svg/tasks/checkmark.svg'
import TasksCrossRed from './svg/tasks/cross_red.svg'
import TasksSkip from './svg/tasks/skip.svg'

export const SvgContent = {
  tasks: {
    alertTriangle: TasksAlertTriangle,
    checkmark: TasksCheckmark,
    crossRed: TasksCrossRed,
    yellowCheckmark: TasksYellowCheckmark,
    skip: TasksSkip
  },

  login: {
    pinUnderline: LoginPinUnderline
  },

  lesson: {
    rocketLight: LessonRocketLight,
    wheelOfFortuneArrow: LessonWheelOfFortuneArrow
  },

  library: {
    bookmark: LibraryBookmarkIcon,
    chatBuble: LibraryChatBubleIcon,
    clockFilled: LibraryClockFilledIcon,
    progress0: LibraryProgress0,
    progress10: LibraryProgress10,
    progress20: LibraryProgress20,
    progress30: LibraryProgress30,
    progress40: LibraryProgress40,
    progress50: LibraryProgress50,
    progress60: LibraryProgress60,
    progress70: LibraryProgress70,
    progress80: LibraryProgress80,
    progress90: LibraryProgress90,
    progress100: LibraryProgress100
  }
}
