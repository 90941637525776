import { useEffect, useRef } from 'react'
import { useIntersection } from 'react-use'
import classNames from 'classnames'
import { motion, useInView } from 'framer-motion'

import { profilePicturesContent } from '@foxino/components-common'

import { StudentRank } from '@app/data'
import { useTranslation } from '@app/locales'
import { getFullName } from '@app/modules/auth/utils/getFullName'
import { useDashboardMenuHeight } from '@app/modules/common/layout/DashboardLayout/useDashboardMenuHeight'
import { getSafeAreaInset } from '@app/utils/platformUtils'

export type PlayerItemProps = {
  player: StudentRank
  index: number
  onCurrentStudentInView?: (inView: boolean) => void
}

export const PLAYER_ITEM_HEIGHT = 56

export const PlayerItem = ({ player, index, onCurrentStudentInView }: PlayerItemProps) => {
  const { t } = useTranslation('common')

  const ref = useRef(null)

  const bottomBarHeight = useDashboardMenuHeight()
  const safeAreaBottom = getSafeAreaInset()

  const isInView = useInView(ref, {
    margin: `${PLAYER_ITEM_HEIGHT}px 0px ${-PLAYER_ITEM_HEIGHT}px 0px`,
    once: true
  })

  const intersection = useIntersection(ref, {
    root: null,
    rootMargin: `${bottomBarHeight + safeAreaBottom.bottom}px 0px ${-bottomBarHeight - safeAreaBottom.bottom}px 0px`,
    threshold: 1
  })

  useEffect(() => {
    if (onCurrentStudentInView) {
      onCurrentStudentInView(intersection && intersection.intersectionRatio < 1 ? false : true)
    }
  }, [intersection])

  const { backgroundColor, image } = profilePicturesContent[player.student.userProfile.pictureUrl || 'image1']

  return (
    <motion.div
      ref={ref}
      style={{ height: PLAYER_ITEM_HEIGHT }}
      className={classNames(
        'flex w-full flex-row justify-between rounded-2xl px-2 text-right text-base font-extrabold antialiased',
        player.currentStudent
          ? 'bg-gradient-to-r from-[#D3C3FF] to-[#CEBDFF] text-[#7164E9] shadow-m'
          : 'transparent text-[#5B6C9B]'
      )}
      initial="hidden"
      animate={isInView ? 'visible' : 'hidden'}
      variants={itemVariants}
      transition={{ duration: 0.25 }}
    >
      <div className="flex max-w-[80%] flex-row items-center gap-2">
        <span className="w-[24px]">{index + 4}.</span>
        <div className="h-10 w-10 rounded-full p-1" style={{ backgroundColor }}>
          <img className="pointer-events-none h-full w-full" src={image} />
        </div>
        <span className="line-clamp-1 max-w-[70%] truncate">
          {getFullName(player.student.userProfile.firstName, player.student.userProfile.lastName)}
        </span>
      </div>

      <div className="flex flex-row items-center justify-center gap-1 text-lg">
        {(player.points || 0).toLocaleString()} {t('point')}
      </div>
    </motion.div>
  )
}

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 }
}
