export const EmojiContent = {
  starStruck: 'https://foxinostorage.blob.core.windows.net/static/FE/Emoji/star-struck.gif',
  smilingFaceWithHearts: 'https://foxinostorage.blob.core.windows.net/static/FE/Emoji/smiling-face-with-hearts.gif',
  nerdFace: 'https://foxinostorage.blob.core.windows.net/static/FE/Emoji/nerd-face.gif',
  beamingFaceWithSmilingEyes:
    'https://foxinostorage.blob.core.windows.net/static/FE/Emoji/beaming-face-with-smiling-eyes.gif',
  smilingFaceWithHeartEyes:
    'https://foxinostorage.blob.core.windows.net/static/FE/Emoji/smiling-face-with-heart-eyes.gif',
  partyingFace: 'https://foxinostorage.blob.core.windows.net/static/FE/Emoji/partying-face.gif',
  winkingFace: 'https://foxinostorage.blob.core.windows.net/static/FE/Emoji/winking-face.gif',
  smilingFaceWithSunglasses:
    'https://foxinostorage.blob.core.windows.net/static/FE/Emoji/smiling-face-with-sunglasses.gif',
  disguisedFace: 'https://foxinostorage.blob.core.windows.net/static/FE/Emoji/disguised-face.gif',
  shushingFace: 'https://foxinostorage.blob.core.windows.net/static/FE/Emoji/shushing-face.gif',
  smirkingFace: 'https://foxinostorage.blob.core.windows.net/static/FE/Emoji/smirking-face.gif',
  upsideDownFace: 'https://foxinostorage.blob.core.windows.net/static/FE/Emoji/upside-down-face.gif',
  astonishedFace: 'https://foxinostorage.blob.core.windows.net/static/FE/Emoji/astonished-face.gif',
  squintingFaceWithTongue: 'https://foxinostorage.blob.core.windows.net/static/FE/Emoji/squinting-face-with-tongue.gif',
  faceWithMonocle: 'https://foxinostorage.blob.core.windows.net/static/FE/Emoji/face-with-monocle.gif',
  zipperMouthFace: 'https://foxinostorage.blob.core.windows.net/static/FE/Emoji/zipper-mouth-face.gif',
  cowboyHatFace: 'https://foxinostorage.blob.core.windows.net/static/FE/Emoji/cowboy-hat-face.gif',
  faceInClouds: 'https://foxinostorage.blob.core.windows.net/static/FE/Emoji/face-in-clouds.gif',
  coldFace: 'https://foxinostorage.blob.core.windows.net/static/FE/Emoji/cold-face.gif',
  woozyFace: 'https://foxinostorage.blob.core.windows.net/static/FE/Emoji/woozy-face.gif',
  pileOfPoo: 'https://foxinostorage.blob.core.windows.net/static/FE/Emoji/pile-of-poo.gif'
}
