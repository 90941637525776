import { useMemo } from 'react'
import sample from 'lodash/sample'

import { ImageContent } from '@app/assets/ImageContent'
import { Heading } from '@app/components'
import { useTranslation } from '@app/locales'

export const NoPackageSection = () => {
  const { t } = useTranslation('library')

  const randomImgData = useMemo(
    () =>
      sample([
        ImageContent.error.img1,
        ImageContent.error.img2,
        ImageContent.error.img3,
        ImageContent.error.img4,
        ImageContent.error.img5
      ]),
    []
  )

  return (
    <div className="flex h-full w-full flex-col items-center gap-4 pb-8">
      <img
        className="mt-20 flex h-[90%] max-h-40 w-[90%] max-w-60 items-center justify-center md:max-h-60"
        src={randomImgData}
      />

      <Heading size="l" className="text-neutral50">
        {t('noPackage')}
      </Heading>
    </div>
  )
}
