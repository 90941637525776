import classNames from 'classnames'
import { Check, Lock01 } from '@untitled-ui/icons-react'

import { StudentLibraryRankLevel } from '@app/data'
import { useTranslation } from '@app/locales'

type Props = {
  level: StudentLibraryRankLevel
  selectLevel?: (level: StudentLibraryRankLevel) => void
  readonly?: boolean
  isRookieEnabled?: boolean
  isProEnabled?: boolean
  isProLocked?: boolean
  isLegendEnabled?: boolean
  isLegendLocked?: boolean
}

export const LevelSwitch = ({
  level,
  selectLevel,
  readonly = false,
  isRookieEnabled = false,
  isProEnabled = false,
  isProLocked = false,
  isLegendEnabled = false,
  isLegendLocked = false
}: Props) => {
  const { t } = useTranslation('library')

  if (readonly) {
    return (
      <div className="flex w-full items-center justify-center gap-2">
        <div className="text-center text-[18px] font-bold text-white underline underline-offset-4">
          {t(`level.${level}`)}
        </div>
      </div>
    )
  }

  return (
    <div className="flex w-full items-center justify-between gap-2">
      <div className="flex w-full justify-center gap-4">
        {isRookieEnabled && (
          <div
            className={classNames(
              'text-[18px flex cursor-pointer items-center justify-center gap-1 text-[18px]',
              level === StudentLibraryRankLevel.PRO || level === StudentLibraryRankLevel.LEGEND
                ? 'font-semibold text-[#C3A3EC]'
                : 'font-bold text-white underline underline-offset-4'
            )}
            onClick={() => selectLevel?.(StudentLibraryRankLevel.ROOKIE)}
          >
            {!isProLocked && <Check className="h-4 w-4" />}
            {t('level.ROOKIE')}
          </div>
        )}

        {isProEnabled && (
          <div
            className={classNames(
              'text-[18px flex cursor-pointer items-center justify-center gap-1 text-[18px]',
              level === StudentLibraryRankLevel.ROOKIE || level === StudentLibraryRankLevel.LEGEND
                ? 'font-semibold text-[#C3A3EC]'
                : 'font-bold text-white underline underline-offset-4'
            )}
            onClick={() => !isProLocked && selectLevel?.(StudentLibraryRankLevel.PRO)}
          >
            {!isLegendLocked && <Check className="h-4 w-4" />}
            {t('level.PRO')}
            {isProLocked && <Lock01 className="h-4 w-4" />}
          </div>
        )}

        {isLegendEnabled && (
          <div
            className={classNames(
              'text-[18px flex cursor-pointer items-center justify-center gap-1 text-[18px]',
              level !== StudentLibraryRankLevel.LEGEND
                ? 'font-semibold text-[#C3A3EC]'
                : 'font-bold text-white underline underline-offset-4'
            )}
            onClick={() => !isLegendLocked && selectLevel?.(StudentLibraryRankLevel.LEGEND)}
          >
            {t('level.LEGEND')}
            {isLegendLocked && <Lock01 className="h-4 w-4" />}
          </div>
        )}
      </div>
    </div>
  )
}
