import classNames from 'classnames'

import { Loader } from '@foxino/components-web'

import { ErrorSection } from '@app/modules/lesson/components'

type SizeType = 'large' | 'medium' | 'small' | 'xs'

type Props = Omit<React.HTMLProps<HTMLDivElement>, 'size'> &
  React.PropsWithChildren & {
    size?: SizeType
    loading: boolean
    error?: boolean
    showChildrenOnLoading?: boolean
    withoutErrorImage?: boolean
    refetch?: () => void
  }

/**
 * @group Components
 * @category Props
 */
export const LoaderWrapper = ({
  size = 'large',
  loading = true,
  showChildrenOnLoading = false,
  withoutErrorImage = false,
  children,
  className,
  error,

  ...restProps
}: Props) => {
  if ((!loading || showChildrenOnLoading) && !error) {
    return children
  }

  const handleOnClick = (event) => {
    event.stopPropagation()
  }

  if (error) {
    return <ErrorSection withoutErrorImage={withoutErrorImage} />
  }

  return (
    <div
      {...restProps}
      onClick={handleOnClick}
      className={classNames('flex h-full w-full flex-row items-center justify-center', className)}
    >
      {loading ? <Loader className="fill-neutral50" size={size} /> : children}
    </div>
  )
}
