import { NetworkStatus, QueryHookOptions } from '@apollo/client'

import { StudentLibraryDetailQuery, StudentLibraryDetailQueryVariables, useStudentLibraryDetailQuery } from '@app/data'

export const useStudentLibraryDetail = (
  baseOptions?: QueryHookOptions<StudentLibraryDetailQuery, StudentLibraryDetailQueryVariables>
) => {
  const query = useStudentLibraryDetailQuery({
    ...baseOptions,
    notifyOnNetworkStatusChange: true,
    refetchWritePolicy: 'overwrite',
    nextFetchPolicy: 'network-only'
  })

  return {
    refetch: query.refetch,
    refetching: query.networkStatus === NetworkStatus.refetch,
    loading: query.loading,
    error: query.error,
    detail: query.data?.studentLibraryDetail
  }
}
