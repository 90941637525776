import { useToggle } from 'react-use'
import classNames from 'classnames'
import { ChevronRight } from '@untitled-ui/icons-react'

import { SvgContent } from '@app/assets/SvgContent'
import { ActivityResult, FlashCardAnswer } from '@app/data'
import { useTranslation } from '@app/locales'

type Props = {
  item: FlashCardAnswer
}

export const PackageDetailItem = ({ item }: Props) => {
  const { t } = useTranslation('common')
  const [isExpanded, toggleIsExpanded] = useToggle(false)

  let assignment: string = '',
    userAnswer: string = '',
    correctAnswer: string = '',
    isWasCorrect = false,
    isWasAlmostCorrect = false,
    isWasWrong = false,
    successRate = 0

  const isOneAnswerFlashCard = item.userAnswers.length === 1

  if (isOneAnswerFlashCard) {
    assignment = item.userAnswers[0]?.assignmentText || ''
    userAnswer = item.userAnswers[0]?.userAnswer || ''
    correctAnswer = item.userAnswers[0]?.correctAnswer || ''
    isWasCorrect = item.userAnswers[0]?.result === ActivityResult.CORRECT
    isWasAlmostCorrect = item.userAnswers[0]?.result === ActivityResult.ALMOST_CORRECT
    isWasWrong = item.userAnswers[0]?.result === ActivityResult.WRONG
    successRate = isWasCorrect ? 100 : isWasAlmostCorrect ? 75 : 0
  } else {
    successRate =
      (item.userAnswers.filter((answer) => answer.result === ActivityResult.CORRECT).length / item.userAnswers.length) *
      100

    assignment = item.flashCardFullScreenType
      ? t(`flashCardFullScreenType.${item.flashCardFullScreenType}`)
      : t(`flashCardResponseType.${item.flashCardResponseType}`)

    userAnswer = `${item.userAnswers.filter((answer) => answer.result === ActivityResult.CORRECT).length}/${
      item.userAnswers.length
    }`

    // we usually get 4 options for matching games
    // so 3/4 = 75% could be treated as "almost correct"
    isWasCorrect = successRate === 100
    isWasAlmostCorrect = successRate < 100 && successRate >= 75
  }

  const fontColorStyle = isWasCorrect
    ? 'text-[#23c550]'
    : isWasAlmostCorrect
      ? 'text-[#FFAD0A]'
      : isWasWrong
        ? 'text-[#FF004D]'
        : 'text-white'

  return (
    <div
      className={classNames(
        'flex w-full flex-col rounded-xl bg-[#5A47B2] p-2',
        isOneAnswerFlashCard || !isExpanded ? 'min-h-20' : ''
      )}
    >
      <div
        className={classNames(
          'flex h-full w-full cursor-pointer flex-row items-center justify-between gap-2',
          isExpanded ? 'min-h-[64px]' : 'min-h-[60px]'
        )}
        onClick={isOneAnswerFlashCard ? undefined : toggleIsExpanded}
      >
        {isOneAnswerFlashCard ? (
          <>
            <ResultMark result={item.userAnswers[0]?.result} />

            <div className="flex h-full w-full flex-col justify-center gap-1">
              <span className="font-semibold text-white">{assignment}</span>
              <span className={`text-[16px] font-bold ${fontColorStyle}`}>{userAnswer}</span>
              {isWasWrong && (
                <span className="text-[14px] font-bold text-[#23c550]">
                  <span className="text-[14px] font-light text-white">{t('correctAnswer')}</span>
                  {correctAnswer}
                </span>
              )}
            </div>
          </>
        ) : (
          <div className="flex w-full justify-between">
            <div className="flex w-full flex-col gap-1">
              <span className="font-semibold text-white">{assignment}</span>
              {isWasWrong && (
                <span className="text-[14px] font-bold text-[#23c550]">
                  <span className="text-[14px] font-light text-white">{t('correctAnswer')}</span>
                  {correctAnswer}
                </span>
              )}
            </div>
            <div className="flex items-center gap-2">
              <span className={`text-[16px] font-semibold ${fontColorStyle}`}>{userAnswer}</span>
              <ChevronRight className={classNames('text-white transition-all', isExpanded && 'rotate-90')} />
            </div>
          </div>
        )}
      </div>

      {isExpanded && (
        <>
          {item.userAnswers.map((answer, index) => {
            const assignment = answer.assignmentText || ''
            const userAnswer = answer.userAnswer || ''
            const correctAnswer = answer.correctAnswer || ''
            const isWasCorrect = answer.result === ActivityResult.CORRECT
            const isWasWrong = answer.result === ActivityResult.WRONG

            const fontColorStyle = isWasCorrect
              ? 'text-[#23c550]'
              : isWasAlmostCorrect
                ? 'text-[#FFAD0A]'
                : isWasWrong
                  ? 'text-[#FF004D]'
                  : 'text-white'

            return (
              <div
                key={index}
                className="flex items-center gap-2 border-b-[1px] border-[rgba(92,90,220,0.30)] py-[10px] last-of-type:border-b-0"
              >
                <ResultMark result={answer.result} />

                <div className="flex w-full flex-col gap-1">
                  <span className="font-semibold text-white">{assignment}</span>
                  <span className={`text-[16px] font-bold ${fontColorStyle}`}>{userAnswer}</span>
                  {isWasWrong && (
                    <span className="text-[14px] font-bold text-[#23c550]">
                      <span className="text-[14px] font-light text-white">{t('correctAnswer')}</span>
                      {correctAnswer}
                    </span>
                  )}
                </div>
              </div>
            )
          })}
        </>
      )}
    </div>
  )
}

const ResultMark = ({ result }: { result: ActivityResult }) => {
  const isWasCorrect = result === ActivityResult.CORRECT
  const isWasAlmostCorrect = result === ActivityResult.ALMOST_CORRECT
  const isWasWrong = result === ActivityResult.WRONG
  const isWasSkipped = result === ActivityResult.SKIPPED

  return (
    <div className="h-12 w-12">
      {isWasSkipped && (
        <object className="h-full w-full object-contain" type="image/svg+xml" data={SvgContent.tasks.skip} />
      )}

      {isWasCorrect && (
        <object className="h-full w-full object-contain" type="image/svg+xml" data={SvgContent.tasks.checkmark} />
      )}

      {isWasAlmostCorrect && (
        <object className="h-full w-full object-contain" type="image/svg+xml" data={SvgContent.tasks.yellowCheckmark} />
      )}

      {isWasWrong && (
        <object className="h-full w-full object-contain" type="image/svg+xml" data={SvgContent.tasks.crossRed} />
      )}
    </div>
  )
}
