export enum TranslationType {
  SOURCE = 'source',
  TARGET = 'target'
}

export enum TutorialsEnum {
  SWIPE_TO_CONTINUE = 'SWIPE_TO_CONTINUE'
}

export enum TrainingLocation {
  DASHBOARD = 'dashboard',
  LESSON = 'lesson'
}

export enum HTTPStatusCodeEnum {
  UNAUTHORIZED = 401,
  UNSUPPORTED_MEDIA_TYPE = 415
}

export enum DateFormatEnum {
  DAY_MONTH_YEAR = 'DD.MM.YYYY',
  DAY_MONTH_YEAR_SLASH = 'DD/MM/YYYY',
  DAY_MONTH_YEAR_SHORT = 'DD.MM.YY',
  DAY_MONTH = 'DD.MM',
  YEAR_MONTH_DAY = 'YYYY-MM-DD',
  ISO_STRING = 'YYYY-MM-DDTHH:mm:ss.sssZ'
}

export enum MaintenanceStatus {
  UP = 'UP',
  MAINTENANCE = 'MAINTENANCE'
}
