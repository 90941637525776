import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { SplashScreen } from '@capacitor/splash-screen'
import { useKeycloak } from '@react-keycloak/web'
import classNames from 'classnames'
import { QrCode01 } from '@untitled-ui/icons-react'

import { useTimeout } from '@foxino/components-common'
import { Label } from '@foxino/components-web'

import { ImageContent } from '@app/assets/ImageContent'
import { Button, LoaderWrapper, Text } from '@app/components'
import { Pages } from '@app/config/router/Pages'
import { useTranslation } from '@app/locales'
import { isNative, isNativeIOS, isTablet } from '@app/utils/platformUtils'

import { LanguageSelector } from '../../components/LanguageSelector'
import { AuthState } from '../../model/enums'
import { authStore } from '../../store/authStore'

export const WelcomePage = () => {
  const { t } = useTranslation('login')
  const navigate = useNavigate()
  const { keycloak } = useKeycloak()
  const [callHideTimeout] = useTimeout(1000)
  const [isLoadingVisible, setIsLoadingVisible] = useState(true)

  const authState = authStore.useStore(({ authState }) => authState)
  const isLoading = authState === AuthState.LOGIN_IN_PROGRESS

  const handleOnLoginClick = () => {
    const url = keycloak.createLoginUrl()

    window.location.href = url
  }

  const handleOnQRLoginClick = () => {
    navigate(Pages.QR_LOGIN, { replace: false })
  }

  const handleOnHelpClick = (e: any) => {
    e.preventDefault()
    const url = 'https://www.foxino.com/foxino-contact-2'

    navigate(Pages.WEBVIEW_PAGE, { state: { url } })
  }

  const handleOnPrivacyClick = (e: any) => {
    e.preventDefault()
    const url = 'https://www.foxino.com/privacy-policy-2'

    navigate(Pages.WEBVIEW_PAGE, { state: { url } })
  }

  useEffect(() => {
    callHideTimeout(() => {
      SplashScreen.hide({ fadeOutDuration: 500 })
    })

    const timeout = setTimeout(() => {
      setIsLoadingVisible(false)
    }, 500)

    return () => clearTimeout(timeout)
  }, [])

  return (
    <div
      style={{ paddingTop: 'calc(env(safe-area-inset-top) + 4px)' }}
      className="flex h-full w-full flex-col items-center justify-center"
    >
      <LoaderWrapper loading={isLoading || isLoadingVisible}>
        <div
          className={classNames(
            'flex h-full w-full max-w-[1024px] flex-col items-center justify-between overflow-y-auto p-4 pb-24 md:justify-start md:gap-2 md:overflow-hidden md:pt-8',
            isNativeIOS() && !isTablet() ? 'pt-0' : 'pt-4'
          )}
        >
          <section className="flex w-full flex-col items-center justify-center">
            <div className="flex w-full flex-row justify-between">
              <div className="static top-0 mx-2 flex w-full justify-between">
                <img className="h-8" draggable={false} src="images/foxino_logo.svg" />

                <div className="right-4">
                  <LanguageSelector />
                </div>
              </div>

              <div className="flex-1"></div>
            </div>

            <div className="relative flex w-full justify-center">
              <object className="absolute -top-4 z-10 max-h-[35vh]" type="image/svg+xml" data="images/welcome_bg.svg">
                Welcome
              </object>
              <img
                className="relative left-2 top-8 z-20 h-full max-h-[35vh] md:h-[400px]"
                src={ImageContent.common.purpleFoxinoClosedEyes}
                alt="foxino"
              />
            </div>
          </section>

          <section className="mt-12 flex max-w-[580px] flex-col gap-4">
            <h1 className="w-full text-center text-2xl font-bold text-[#F1F1F1] md:text-3xl">
              <span>{t('welcome.the')}</span>
              <span className="text-[#FFCD9E]">{t('welcome.prime')}</span>
              <span>{t('welcome.title')}</span>
            </h1>
            <Label className="w-full px-4 text-center text-sm font-light text-[#F1F1F1] md:text-base">
              {t('welcome.description')}
            </Label>
          </section>

          <section className="mt-6 flex w-full flex-col items-center justify-center">
            <div className="flex w-full max-w-[480px] flex-col gap-2">
              <Button btnClassName="flex w-full flex-row gap-2" onClick={handleOnQRLoginClick}>
                <QrCode01 />
                <Text size="l" weight="bold">
                  {t('welcome.qrCode')}
                </Text>
              </Button>

              <Button btnClassName="!font-thin" mode="primary-outline" onClick={handleOnLoginClick}>
                {t('welcome.login')}
              </Button>
            </div>
          </section>

          <section className="mt-8 flex w-full flex-col items-center text-[14px] font-light text-neutral200">
            {!isNative() && (
              <div>
                {t('welcome.loginAsTeacher')}
                <a className="underline" href="https://teacher.foxino.com/">
                  {t('welcome.teacherApp')}
                </a>
              </div>
            )}
            <div className="flex w-full justify-center gap-2 underline">
              <div className="underline" onClick={handleOnPrivacyClick}>
                {t('welcome.privacy')}
              </div>
              -
              <div className="underline" onClick={handleOnHelpClick}>
                {t('welcome.help')}
              </div>
            </div>
          </section>
        </div>
      </LoaderWrapper>
    </div>
  )
}
