import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { createSearchParams } from 'react-router-dom'

import { Pages } from '@app/config/router/Pages'
import { LearningAssignmentType, Skill, Subject, useStartStudentLibrarySubPackageMutation } from '@app/data'
import { localSettingsStore } from '@app/modules/common/store/localSettingsStore'
import { lessonInputsStore } from '@app/modules/lesson/store'
import { useProfileSettings } from '@app/modules/settings/data'
import { hapticsImpactMedium } from '@app/utils/hapticsUtils'
import { logError } from '@app/utils/logsUtils'

import { SubPackageDetail, SubPackageDetailLevel } from '../model/types'

interface UseStartPackageHandlerParams {
  id?: string
  currentDetail?: SubPackageDetail
  currentLevel?: SubPackageDetailLevel
}

export const useStartPackageHandler = ({ id, currentLevel, currentDetail }: UseStartPackageHandlerParams) => {
  const navigate = useNavigate()

  const { studentProfile } = useProfileSettings()
  const { classrooms } = studentProfile || {}
  const classroomId = localSettingsStore.useStore((state) => state.classroomId)
  const selectedClassroom = classrooms?.find((classroom) => classroom.id === classroomId)
  const selectedSubject = selectedClassroom ? selectedClassroom.subject : Subject.ENGLISH // just placeholder

  const [startPackage] = useStartStudentLibrarySubPackageMutation()

  const handleStartPackage = useCallback(async () => {
    try {
      if (!currentDetail || !currentLevel) {
        return
      }

      const result = await startPackage({
        variables: {
          subPackageId: id || '',
          level: currentLevel.level
        }
      })

      if (result?.data?.startStudentLibrarySubPackage) {
        lessonInputsStore.setNewLearningAssignment(
          result?.data?.startStudentLibrarySubPackage,
          LearningAssignmentType.STUDENT_LIBRARY_EXERCISE,
          [Skill.READING, Skill.WRITING],
          undefined, // TODO: handle deadline if needed
          selectedSubject,
          currentDetail.name,
          Pages.DASHBOARD_LIBRARY
        )

        hapticsImpactMedium()

        navigate({
          pathname: Pages.LESSON_BEFORE,
          search: `?${createSearchParams({ type: 'even' })}`
        })
      }
    } catch (error) {
      logError(error, 'useStartPackageHandler', 'handleStartPackage')
    }
  }, [id, currentLevel, currentDetail, selectedSubject])

  return { handleStartPackage }
}
