import { Suspense, useEffect } from 'react'
import { I18nextProvider } from 'react-i18next'
import { RouterProvider } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { registerSW } from 'virtual:pwa-register'

import { initializeSentry, isSentryInitialized } from '@app/config/analytics/initializeSentry'
import { browserRouter } from '@app/config/router/browserRouter'
import i18n from '@app/locales/i18n'
import { GraphqlProvider } from '@app/modules/common/providers'

import { KeycloakProvider } from './modules/auth/providers/KeycloakProvider'
import { isNative } from './utils/platformUtils'

registerSW({
  immediate: true,
  // TODO: test this if it works
  onNeedRefresh() {
    if (confirm('New version available. Do you want to reload the app?')) {
      window.location.reload()
    }
  },
  onOfflineReady() {
    console.info('The application is ready to work offline.')
  }
})

if (!isSentryInitialized() && import.meta.env.DEV === false) {
  initializeSentry()
}

const App = () => {
  console.info('[App] window.location.origin', window.location.origin)

  // TODO: Android has problem with plugins on different websites. Check if there is some workaround and then uncomment this
  // https://github.com/ionic-team/capacitor/issues/7454
  // const shouldChangeOrigin =
  //   window.location.origin !== (isIOS() ? Constants.IOS_NATIVE_LOCALHOST : Constants.ANDROID_NATIVE_LOCALHOST) &&
  //   !config.server?.url

  // // Redirect to localhost web server (Bundled javascript and html) if the app is running on native
  // if (isNative() && shouldChangeOrigin) {
  //   console.info('[App] Redirecting to localhost web server of native app')
  //   window.location.href =
  //     (isIOS() ? Constants.IOS_NATIVE_LOCALHOST : Constants.ANDROID_NATIVE_LOCALHOST) +
  //     window.location.pathname +
  //     window.location.search +
  //     window.location.hash
  // }

  useEffect(() => {
    const style = document.createElement('style')

    if (isNative()) {
      // hide all scrollbars on native app
      style.textContent = `
        * {
          scrollbar-width: none; /* Firefox */
          -ms-overflow-style: none; /* Internet Explorer 10+ */
        }
        *::-webkit-scrollbar {
          display: none; /* Chrome, Safari, Opera */
        }
      `
    }

    document.head.appendChild(style)

    return () => {
      document.head.removeChild(style)
    }
  }, [])

  // if (isNative() && shouldChangeOrigin) {
  //   return <LoaderWrapper id="change-origin-loader" loading className="h-screen" />
  // }

  return (
    <I18nextProvider i18n={i18n}>
      <KeycloakProvider>
        <GraphqlProvider>
          <ToastContainer
            autoClose={3000}
            hideProgressBar={true}
            newestOnTop={true}
            closeButton={true}
            draggable={true}
            draggablePercent={10}
          />
          {/* Test of suspense to get rid of errors: "Cannot access 'MainLayout' before initialization" */}
          <Suspense fallback={<div>Loading...</div>}>
            <RouterProvider router={browserRouter} />
          </Suspense>
        </GraphqlProvider>
      </KeycloakProvider>
    </I18nextProvider>
  )
}

export default App
