import { useEffect, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Pages } from '@app/config/router/Pages'
import { MainHeader } from '@app/modules/common'

export const WebviewPage = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const iframeRef = useRef<HTMLIFrameElement>(null)

  const url = location.state?.url

  useEffect(() => {
    if (!url) {
      navigate(Pages.DASHBOARD)
    }
  }, [url, navigate])

  return (
    <div className="absolute h-screen w-full overscroll-none">
      <div className="absolute top-[calc(env(safe-area-inset-top))]">
        <MainHeader isBackBtnVisible />
      </div>

      {url && <iframe ref={iframeRef} src={url} className="h-full w-full" />}
    </div>
  )
}
