import { NetworkStatus, QueryHookOptions } from '@apollo/client'

import {
  StudentLibraryOverviewQuery,
  StudentLibraryOverviewQueryVariables,
  useStudentLibraryOverviewQuery
} from '@app/data'

export const useStudentLibraryOverview = (
  baseOptions?: QueryHookOptions<StudentLibraryOverviewQuery, StudentLibraryOverviewQueryVariables>
) => {
  const query = useStudentLibraryOverviewQuery({
    ...baseOptions,
    notifyOnNetworkStatusChange: true,
    refetchWritePolicy: 'overwrite',
    nextFetchPolicy: 'network-only'
  })

  return {
    refetch: query.refetch,
    refetching: query.networkStatus === NetworkStatus.refetch,
    loading: query.loading,
    error: query.error,
    overview: query.data?.studentLibraryOverview
  }
}
