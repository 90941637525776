import shuffle from 'lodash/shuffle'
import { RefreshCw01 } from '@untitled-ui/icons-react'

import { ImageContent } from '@app/assets/ImageContent'
import { Button, Heading, Text } from '@app/components'
import { useTranslation } from '@app/locales'

type Props = {
  withoutErrorImage?: boolean
}

export const ErrorSection = ({ withoutErrorImage }: Props) => {
  const { t } = useTranslation('error')

  const randomImgData = shuffle([
    ImageContent.error.img1,
    ImageContent.error.img2,
    ImageContent.error.img3,
    ImageContent.error.img4,
    ImageContent.error.img5
  ])[0]

  const handleReloadClick = () => {
    // hard reload
    window.location.reload()
  }

  return (
    <div className="flex h-full w-full flex-col items-center justify-center gap-4 pb-8">
      {!withoutErrorImage && (
        <object className="h-[350px] w-[90%] max-w-[480px] md:h-[480px]" type="image/svg+xml" data={randomImgData} />
      )}

      <Heading size="l" className="text-neutral50">
        {t('somethingWentWrong')}
      </Heading>

      <Text className="max-w-screen-md px-8 text-center text-neutral50">{t('somethingWentWrongDescription')}</Text>

      <Button
        className="mt-8 w-[280px]"
        btnClassName="w-full flex flex-row gap-3"
        mode="primary"
        onClick={handleReloadClick}
      >
        <RefreshCw01 />
        <Text size="l" weight="bold">
          {t('reload')}
        </Text>
      </Button>
    </div>
  )
}
