import { ReactNode, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useClickAway } from 'react-use'
import classNames from 'classnames'
import { PlusCircle, QrCode02 } from '@untitled-ui/icons-react'

import { Heading, LoaderWrapper } from '@app/components'
import { Pages } from '@app/config/router/Pages'
import { useTranslation } from '@app/locales'
import { useStudentsRanks } from '@app/modules/ranks/data'
import { useProfileSettings } from '@app/modules/settings/data'
import { useStudentInfo } from '@app/modules/tasks/data'
import { subjectToFlagRoundIcon } from '@app/utils/iconUtils'
import { isBrowser, isMobile, isNative, isNativeIOS } from '@app/utils/platformUtils'

import { localSettingsStore } from '../../store/localSettingsStore'
import { GoBackBtn } from '../GoBackBtn'

import './MainHeader.styles.css'

type Props = {
  className?: string
  isBackBtnVisible?: boolean
  isQrLoginBtnVisible?: boolean
  isXpBarVisible?: boolean
  isSwitchClassEnable?: boolean
  isSwitchClassBannerVisible?: boolean
  onBackBtnClick?: () => void
  toggleSwitchClassBanner?: (flag?: boolean) => void
  title?: ReactNode
  titleClassName?: string
}

export const MainHeader = ({
  className,
  isBackBtnVisible = false,
  isQrLoginBtnVisible = false,
  isXpBarVisible = false,
  isSwitchClassEnable = false,
  isSwitchClassBannerVisible = false,
  onBackBtnClick,
  toggleSwitchClassBanner,
  title = '',
  titleClassName
}: Props) => {
  const { t } = useTranslation(['settings', 'common'])
  const navigate = useNavigate()

  const { studentProfile, loading, refetching, error } = useProfileSettings()
  const { classrooms } = studentProfile || {}

  const classroomId = localSettingsStore.useStore((state) => state.classroomId)

  const { studentInfo } = useStudentInfo()
  const { overallRanks } = useStudentsRanks(classroomId, { skip: !studentInfo?.student.classrooms || !classroomId })
  const totalPoints = overallRanks.find((rank) => rank.currentStudent)?.points || 0

  const selectedClassroom = classrooms?.find((classroom) => classroom.id === classroomId)
  const SelectedClassroomFlag = selectedClassroom
    ? subjectToFlagRoundIcon[selectedClassroom.subject]
    : subjectToFlagRoundIcon['ENGLISH'] // just placeholder

  const ref = useRef(null)
  useClickAway(ref, () => {
    toggleSwitchClassBanner?.(false)
  }, ['click'])

  useEffect(() => {
    if (studentInfo?.student.classrooms) {
      if (studentInfo.student.classrooms.length > 0) {
        const belongsToClassroom = studentInfo?.student.classrooms?.some((classroom) => classroom.id === classroomId)

        if (!belongsToClassroom) {
          // default we select the first classroom
          localSettingsStore.setClassroomId(studentInfo?.student.classrooms[0].id)
        }
      } else {
        localSettingsStore.setClassroomId(undefined)
      }
    }
  }, [classroomId, studentInfo?.student.classrooms])

  const handleGoBack = () => {
    return onBackBtnClick ? onBackBtnClick() : navigate(-1)
  }

  const handleOpenSocialActivityLogin = () => {
    navigate(Pages.SOCIAL_ACTIVITY_LOGIN)
  }

  const handleOnAddClassroomClick = () => {
    navigate(Pages.SETTINGS_ADD_CLASSROOM)
  }

  return (
    <header
      ref={ref}
      className={classNames(
        // Banner in LearningPathPage has zIndex set to 999
        'absolute z-[1000] max-w-[1312px] md:rounded-b-2xl md:pt-4',
        (isXpBarVisible || isBackBtnVisible || isSwitchClassBannerVisible) && 'w-full',
        isSwitchClassBannerVisible ? 'bg-[#5140bd]' : '',
        isNative() ? 'pt-1' : isMobile() ? 'pt-2' : 'pt-4',
        className
      )}
    >
      <div
        className={classNames(
          'flex h-12 w-full flex-row items-center justify-between',
          isSwitchClassEnable && !isQrLoginBtnVisible && !isBackBtnVisible ? 'pl-4 md:pl-8' : 'px-4 md:px-8'
        )}
      >
        <div className="flex h-full flex-1 items-start">
          {isSwitchClassEnable && (
            <button
              className={classNames('h-12 w-12 cursor-pointer text-neutral50')}
              onClick={() => toggleSwitchClassBanner?.()}
            >
              {classroomId && <SelectedClassroomFlag width={30} height={30} />}
            </button>
          )}

          {isQrLoginBtnVisible && (
            <button className="h-12 w-12 text-neutral50" onClick={handleOpenSocialActivityLogin}>
              <QrCode02 />
            </button>
          )}

          {isBackBtnVisible && (
            <div
              className={classNames(
                'absolute left-4 h-12 w-12 md:left-8 md:top-4',
                isNativeIOS() && isMobile() ? 'top-1' : isMobile() ? 'top-2' : 'top-4'
              )}
            >
              <GoBackBtn onClick={handleGoBack} />
            </div>
          )}
        </div>

        <div className={classNames('flex-2 flex w-[70%] items-center justify-center text-center')}>
          <Heading size="l" className={classNames('text-neutral50', titleClassName)}>
            {title}
          </Heading>
        </div>

        <div className="flex flex-1 items-center justify-end">
          {isXpBarVisible && (
            <div className="flex h-full w-[100px] items-center justify-end md:w-[125px]">
              <span
                className="text-nowrap text-left font-[GhostKidAOEPro] text-[20px] text-fill-transparent"
                style={{
                  background: 'linear-gradient(180deg, #FF9C41 41.7%, #FF8413 59.93%)',
                  backgroundClip: 'text',
                  WebkitBackgroundClip: 'text'
                }}
              >
                {totalPoints.toLocaleString()} {t('point')}
              </span>
            </div>
          )}
        </div>
      </div>

      {isSwitchClassBannerVisible && (
        <div
          className={classNames(
            'switchWrapper mt-2 flex h-32 w-full flex-row items-end justify-start gap-4 overflow-x-auto border-t-2 border-t-[#6e5dd4] px-2 pt-4',
            isMobile()
              ? isBrowser()
                ? 'before:left-[18px] before:mt-[52px] after:left-[18px] after:mt-[52px]'
                : 'before:left-[18px] before:mt-[46px] after:left-[18px] after:mt-[46px]'
              : 'before:left-[34px] before:mt-[62px] after:left-[34px] after:mt-[62px]'
          )}
        >
          <LoaderWrapper loading={loading || refetching} error={!!error}>
            {/* existing options */}
            {classrooms?.map((classroom) => {
              const LanguageFlag = subjectToFlagRoundIcon[classroom.subject] || subjectToFlagRoundIcon['ENGLISH'] // default value
              const fullObjectName = classroom.name + ` - ${t(`subject.${classroom.subject}`)}`

              return (
                <div
                  key={classroom.id}
                  className="flex h-full min-w-20 max-w-20 flex-col items-center justify-start gap-2"
                >
                  <div
                    className={classNames(
                      'flex h-12 w-12 cursor-pointer items-center justify-center rounded-full',
                      classroomId === classroom.id ? 'border-2 border-[#d6cfff]' : ''
                    )}
                    onClick={() => {
                      localSettingsStore.setClassroomId(classroom.id)
                      toggleSwitchClassBanner?.(false)
                    }}
                  >
                    <LanguageFlag className="h-10 w-10" />
                  </div>
                  <span
                    className={classNames(
                      'line-clamp-2 w-full break-before-auto break-words text-center text-neutral50',
                      classroomId === classroom.id ? 'font-bold' : ''
                    )}
                  >
                    {fullObjectName}
                  </span>
                </div>
              )
            })}

            {/* add new option */}
            <div className="flex h-full min-w-20 max-w-20 flex-col items-center justify-start gap-2">
              <div
                className="flex h-12 w-12 cursor-pointer items-center justify-center rounded-full"
                onClick={handleOnAddClassroomClick}
              >
                <PlusCircle className="h-10 w-10 text-[#a092ff]" />
              </div>

              <span className="line-clamp-2 text-center text-neutral50">{t('classrooms.addNew')}</span>
            </div>
          </LoaderWrapper>
        </div>
      )}
    </header>
  )
}
