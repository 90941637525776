import { useEffect, useRef, useState } from 'react'
import * as SDK from 'microsoft-cognitiveservices-speech-sdk'

import Constants from '@app/constants'
import { PronunciationInput } from '@app/data'
import i18n from '@app/locales/i18n'
import { cognitiveServicesStore } from '@app/modules/lesson/store'
import { SpeechRecognitionPlugin } from '@app/plugins/SpeechRecognitionPlugin'
import { showToast } from '@app/utils/commonUtils'
import { logError } from '@app/utils/logsUtils'
import { isNative } from '@app/utils/platformUtils'

// initialize sdk
SDK.Recognizer.enableTelemetry(false)
const speechConfig = SDK.SpeechConfig.fromSubscription(Constants.SPEECH_KEY, Constants.SPEECH_REGION)
const audioConfigFromMic = SDK.AudioConfig.fromDefaultMicrophoneInput()
const ERROR_MESSAGE = `${i18n.t('training:tryAgain')}...`

export type RecordingType = 'pronunciation' | 'translation'

export type UseSttReturnType = {
  startRecognizing: (type: RecordingType) => void
  stopRecognizing: () => void
}

type Props = {
  locale: string
  recordDuration?: number
  type: RecordingType
  startRecognizingCallback?: () => void
  recognizingStartedCallback?: () => void
  stopRecognizingCallback?: () => void
  waitingRecognizedCallback?: () => void
}

/**
 * Real-time speech to text synthesis using MS Azure Speech service
 */
export const useStt = ({
  locale,
  type = 'translation',
  startRecognizingCallback,
  recognizingStartedCallback,
  stopRecognizingCallback
}: Props): UseSttReturnType => {
  const speechRecognizer = useRef<SDK.SpeechRecognizer>()

  const { phraseList } = cognitiveServicesStore.useStore(({ phraseList }) => ({
    phraseList
  }))
  const [isPermissionGranted, setIsPermissionGranted] = useState<boolean>(true)

  const initializeNative = () => {
    try {
      SpeechRecognitionPlugin.initConfig({
        region: Constants.SPEECH_REGION,
        subscriptionKey: Constants.SPEECH_KEY
      })

      checkNativePermission().then((result) => {
        setIsPermissionGranted(result)
      })
    } catch (error) {
      logError(error, 'useStt', 'useEffect', 'Failed to configure speech recognition')
    }
  }

  const initializeWeb = () => {
    speechConfig.speechRecognitionLanguage = locale
    speechConfig.speechSynthesisLanguage = locale

    speechRecognizer.current = new SDK.SpeechRecognizer(speechConfig, audioConfigFromMic)

    speechRecognizer.current.recognizing = (_s, e) => {
      console.info(`[recognizing]: ${e.result.text}`)

      if (type === 'translation') {
        cognitiveServicesStore.setRecognizedText(e.result.text)

        const recognizedTextMatch = phraseList.some((pl) => sanitizeString(pl) == sanitizeString(e.result.text))

        if (recognizedTextMatch) {
          stopRecognizing()
        }
      }
    }

    speechRecognizer.current.recognized = (_s, e) => {
      stopRecognizing()

      if (e.result.reason === SDK.ResultReason.RecognizedSpeech) {
        cognitiveServicesStore.setIsRecognized(true)

        if (type === 'pronunciation') {
          const { assessment, hasError, word } = parsePronunciationAssessment(e.result.json)

          if (assessment) {
            cognitiveServicesStore.setPronunciationAssessment(assessment)
            cognitiveServicesStore.setRecognizedText(word || e.result.text)
          } else if (word) {
            cognitiveServicesStore.setRecognizedText(word)
          } else if (hasError) {
            cognitiveServicesStore.setRecognizedText(ERROR_MESSAGE)
          }
        } else {
          cognitiveServicesStore.setRecognizedText(e.result.text)
        }
      } else if (e.result.reason === SDK.ResultReason.NoMatch) {
        console.info('[recognized]: NOMATCH - speech could not be recognized.')
      }
    }

    speechRecognizer.current.speechEndDetected = () => {
      console.info('[speechEndDetected]')
      stopRecognizing()
    }

    speechRecognizer.current.canceled = (_s, e) => {
      console.info(`[canceled]: Reason=${e.reason}`)

      if (e.reason === SDK.CancellationReason.Error) {
        logError(e, 'useStt', 'speechRecognizer.canceled', `ErrorDetails=${e.errorDetails}`, false)
      }

      stopRecognizing()
    }

    speechRecognizer.current.sessionStarted = () => {
      console.info('[sessionStarted]')
    }

    speechRecognizer.current.sessionStopped = () => {
      console.info('[sessionStopped]')
      stopRecognizing()
    }
  }

  const startRecognizing = async () => {
    try {
      cognitiveServicesStore.setIsRecognized(false)
      cognitiveServicesStore.setRecognizedText('')
      cognitiveServicesStore.setPronunciationAssessment(undefined)

      if (isNative()) {
        if (!isPermissionGranted) {
          return
        }

        startRecognizingCallback?.()

        await SpeechRecognitionPlugin.start(
          {
            language: locale,
            phraseList,
            isWithPronunciation: type === 'pronunciation'
          },
          ({ text, pronunciationJSON, recognizing }, error) => {
            if (error) {
              logError(error, 'useStt', 'startRecognizing', 'Error starting recognition', false)
              stopRecognizing()
              return
            }

            if (recognizing) {
              // Session started from native code
              cognitiveServicesStore.setIsRecognizing(true)
              recognizingStartedCallback?.()

              return
            }

            if (text) {
              cognitiveServicesStore.setIsRecognized(true)
            }

            if (type === 'pronunciation') {
              if (pronunciationJSON) {
                const { assessment, hasError, word } = parsePronunciationAssessment(pronunciationJSON || '')

                if (assessment) {
                  cognitiveServicesStore.setPronunciationAssessment(assessment)
                  cognitiveServicesStore.setRecognizedText(word || text)
                } else if (word) {
                  cognitiveServicesStore.setRecognizedText(word)
                } else if (hasError) {
                  cognitiveServicesStore.setRecognizedText(ERROR_MESSAGE)
                }

                stopRecognizing()
              }
            } else {
              cognitiveServicesStore.setRecognizedText(text)

              const recognizedTextMatch = phraseList.some((pl) => sanitizeString(pl) == sanitizeString(text))

              if (recognizedTextMatch) {
                stopRecognizing()
              }
            }
          }
        )
      } else {
        // Web platform
        if (phraseList.length > 0 && speechRecognizer.current) {
          // adding a phrase to a phrase list increases its importance, thus making it more likely to be recognized
          SDK.PhraseListGrammar.fromRecognizer(speechRecognizer.current).addPhrases(phraseList)
        }

        cognitiveServicesStore.setIsRecognizing(true)
        startRecognizingCallback?.()

        if (type === 'pronunciation' && speechRecognizer.current) {
          const pronunciationAssessmentConfig = new SDK.PronunciationAssessmentConfig(
            phraseList[0],
            SDK.PronunciationAssessmentGradingSystem.HundredMark,
            SDK.PronunciationAssessmentGranularity.Word,
            true
          )

          pronunciationAssessmentConfig.referenceText = phraseList[0]
          pronunciationAssessmentConfig.enableProsodyAssessment = true
          pronunciationAssessmentConfig.applyTo(speechRecognizer.current)

          recognizingStartedCallback?.()
          console.info('[startRecognizing][startRecognitionOnce]')
        }

        speechRecognizer.current?.startContinuousRecognitionAsync(
          () => {
            console.info('[startRecognizing][startContinuousRecognitionAsync]')
            recognizingStartedCallback?.()
          },
          (err) => console.error('[startRecognizing]: Error starting recognition', err)
        )
      }
    } catch (error) {
      showToast('Error starting recognition' + JSON.stringify(error), { type: 'error' })
      console.error('[startRecognizing]: Error starting recognition', error)
      stopRecognizing()
      stopRecognizingCallback?.()
    }
  }

  const stopRecognizing = async () => {
    try {
      const isRecognizing = cognitiveServicesStore.useStore.getState().isRecognizing

      if (isRecognizing) {
        cognitiveServicesStore.setIsRecognizing(false)

        if (isNative()) {
          stopRecognizingCallback?.()
          SpeechRecognitionPlugin.clearListeners()
          await SpeechRecognitionPlugin.stop()
        } else {
          if (type === 'pronunciation') {
            stopRecognizingCallback?.()
            speechRecognizer.current?.close() // Stop recognizeOnceAsync

            initializeWeb() // Reinitialize the recognizer
          } else {
            speechRecognizer.current?.stopContinuousRecognitionAsync(
              () => {
                console.info('[stopRecognizing][stopContinuousRecognitionAsync]')
                stopRecognizingCallback?.()
              },
              (err) => console.error('[stopRecognizing]: Error stopping recognition', err)
            )
          }
        }
      }
    } catch (error) {
      console.error('[stopRecognizing]: Error stopping recognition', error)
    }
  }

  useEffect(() => {
    if (isNative()) {
      initializeNative()
    }

    return () => {
      if (isNative()) {
        SpeechRecognitionPlugin.clearListeners()
      }
    }
  }, [])

  useEffect(() => {
    initializeWeb()
  }, [phraseList])

  useEffect(() => {
    return () => {
      stopRecognizing()
    }
  }, [])

  return {
    startRecognizing,
    stopRecognizing
  }
}

const sanitizeString = (text: string): string => {
  return text
    .replace(/[?!.,'"`’‘]/g, ' ')
    .replace(/\s+/g, ' ')
    .toLocaleLowerCase()
    .trimEnd()
}

// const errorTypeToMessage = (errorType: string): string => {
//   switch (errorType) {
//     case 'None':
//       return ''
//     case 'Mispronunciation':
//       return i18n.t('training:pronunciationAssessment.incorrectPronunciation')
//     case 'Omission':
//       return i18n.t('training:pronunciationAssessment.omission')
//     case 'Insertion':
//       return i18n.t('training:pronunciationAssessment.insertion')
//     default:
//       return i18n.t('training:pronunciationAssessment.error')
//   }
// }

const checkNativePermission = async (): Promise<boolean> => {
  const { permission } = await SpeechRecognitionPlugin.hasPermission()

  if (permission !== 'granted') {
    const { permission } = await SpeechRecognitionPlugin.requestPermission()
    if (permission !== 'granted') {
      showToast('Permission not granted', { type: 'error' })
      return false
    } else {
      return true
    }
  }

  return true
}

const parsePronunciationAssessment = (
  pronunciationJSON: string
): { assessment: PronunciationInput | undefined; word: string; hasError: boolean } => {
  let hasError: boolean = false,
    assessment: PronunciationInput | undefined = undefined,
    word: string = ''

  try {
    const pronResult = JSON.parse(pronunciationJSON)
    const nBest = pronResult.NBest[0]
    const pronunciationAssessment = nBest?.PronunciationAssessment
    const wordsObject = nBest?.Words

    if (wordsObject) {
      word = wordsObject
        .map((w) => {
          if (w.PronunciationAssessment?.ErrorType && w.PronunciationAssessment?.ErrorType !== 'None') {
            hasError = true
            return ERROR_MESSAGE
          } else if (hasError) {
            return ''
          } else {
            return w.Word
          }
        })
        .join(' ')
    }

    if (pronunciationAssessment) {
      assessment = {
        accuracyScore: pronunciationAssessment.AccuracyScore || 0,
        completenessScore: pronunciationAssessment.CompletenessScore || 0,
        fluencyScore: pronunciationAssessment.FluencyScore || 0,
        pronScore: pronunciationAssessment.PronScore || 0,
        prosodyScore: pronunciationAssessment.ProsodyScore || 0
      }
    }
  } catch (error) {
    logError(error, 'useStt', 'startRecognizing', 'Error parsing pronunciation result', false)
  }

  return {
    assessment,
    word,
    hasError
  }
}
