import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'

import { LoaderWrapper } from '@app/components'
import AppUrlListener from '@app/modules/auth/providers/AppUrlProvider'
import { InstallAppBanner } from '@app/modules/auth/view/InstallAppBanner/InstallAppBanner'
import { isBrowser, isMobileOrTablet, isNative } from '@app/utils/platformUtils'

import { useCheckInternetConnection } from '../../actions/useCheckInternetConnection'
import { useCheckMaintenance } from '../../data/useCheckMaintenance'

export const MainLayout = () => {
  useCheckMaintenance()
  useCheckInternetConnection()

  return (
    <Suspense fallback={<LoaderWrapper loading className="h-screen" />}>
      <AppUrlListener />

      <main className="flex h-full w-full justify-center overflow-y-hidden">
        {isMobileOrTablet() && isBrowser() && !isNative() && <InstallAppBanner />}
        <Outlet />
      </main>
    </Suspense>
  )
}
