import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { LogOut01, Settings02 } from '@untitled-ui/icons-react'

import { profilePicturesContent, useVisibleState } from '@foxino/components-common'

import { ImageContent } from '@app/assets/ImageContent'
import { Button, LoaderWrapper } from '@app/components'
import { Pages } from '@app/config/router/Pages'
import { useTranslation } from '@app/locales'
import { getFullName } from '@app/modules/auth/utils/getFullName'
import { MainHeader, SettingsLayout } from '@app/modules/common'
import { isCurrentPathActive } from '@app/utils/commonUtils'
import { subjectToFlagRoundIcon } from '@app/utils/iconUtils'

import { ExitAppDialog } from '../components'
import { useProfileSettings } from '../data'
import { FeedbackDialog } from '../view/FeedbackDialog/FeedbackDialog'

export const MyProfilePage = () => {
  const navigate = useNavigate()
  const { t } = useTranslation('settings')
  const { pathname } = useLocation()

  const [isDialogVisible, showExitDialog, closeExitDialog] = useVisibleState()
  const [isExitFeedbackDialogVisible, showExitFeedbackDialog, closeExitFeedbackDialog] = useVisibleState()

  const { studentProfile, loading, refetching, error, actions } = useProfileSettings()

  const { userProfile, classrooms } = studentProfile || {}
  const { firstName, lastName, pictureUrl } = userProfile || {}
  const { backgroundColor, image } = profilePicturesContent[pictureUrl || 'image1']

  useEffect(() => {
    if (isCurrentPathActive(pathname, Pages.DASHBOARD_MY_PROFILE, true)) {
      actions.refetchStudentInfo()
    }
  }, [pathname])

  const handleOnProfilePictureEdit = () => {
    navigate(Pages.SETTINGS_PROFILE_PICTURE)
  }

  const handleOnSettingsClick = () => {
    navigate(Pages.SETTINGS)
  }

  const handleOnLogoutClick = () => {
    showExitDialog()
  }

  return (
    <SettingsLayout>
      <ExitAppDialog isDialogVisible={isDialogVisible} onDismiss={closeExitDialog} />
      <FeedbackDialog isDialogVisible={isExitFeedbackDialogVisible} onClose={closeExitFeedbackDialog} />

      <MainHeader isQrLoginBtnVisible isXpBarVisible />

      <div className="flex h-auto w-full max-w-[490px] flex-col items-center gap-8 pt-16">
        <div className="relative flex size-[260px] justify-center md:size-[324px]">
          <svg
            className="absolute -top-8 size-[260px] md:size-[324px]"
            viewBox="0 0 324 324"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g filter="url(#filter0_f_272_21809)">
              <circle cx="162" cy="162" r="126.5" stroke="url(#paint0_radial_272_21809)" strokeWidth="15" />
            </g>
            <defs>
              <filter
                id="filter0_f_272_21809"
                x="0.799999"
                y="0.799999"
                width="322.4"
                height="322.4"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="13.6" result="effect1_foregroundBlur_272_21809" />
              </filter>
              <radialGradient
                id="paint0_radial_272_21809"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(162 162) rotate(90) scale(119)"
              >
                <stop stopColor="white" />
                <stop offset="1" stopColor="#E69FFF" />
              </radialGradient>
            </defs>
          </svg>

          <div
            style={{
              backgroundColor,
              borderWidth: '3px',
              borderColor: '#F3C5FF'
            }}
            className="relative top-0 size-[200px] rounded-full p-4 md:size-[260px]"
          >
            <img src={image} className="pointer-events-none h-full w-full" />

            <button
              className="absolute -bottom-4 -right-4 rounded-full bg-brand-50 p-2"
              onClick={handleOnProfilePictureEdit}
            >
              <svg viewBox="0 0 24 24" fill="#8B7DEC" className="size-6">
                <path d="M12 9a3.75 3.75 0 1 0 0 7.5A3.75 3.75 0 0 0 12 9Z" />
                <path
                  fillRule="evenodd"
                  d="M9.344 3.071a49.52 49.52 0 0 1 5.312 0c.967.052 1.83.585 2.332 1.39l.821 1.317c.24.383.645.643 1.11.71.386.054.77.113 1.152.177 1.432.239 2.429 1.493 2.429 2.909V18a3 3 0 0 1-3 3h-15a3 3 0 0 1-3-3V9.574c0-1.416.997-2.67 2.429-2.909.382-.064.766-.123 1.151-.178a1.56 1.56 0 0 0 1.11-.71l.822-1.315a2.942 2.942 0 0 1 2.332-1.39ZM6.75 12.75a5.25 5.25 0 1 1 10.5 0 5.25 5.25 0 0 1-10.5 0Zm12-1.5a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
        </div>

        <div className="flex w-full flex-col items-center justify-center px-4">
          <span className="text-center text-[36px] font-bold text-neutral50">{getFullName(firstName, lastName)}</span>
        </div>

        <div className="flex w-full flex-row justify-between gap-2 px-4 md:gap-8">
          <Button
            icon={<Settings02 className="h-5 w-5 rounded-full text-[#2C0077]" />}
            mode="primary-outline"
            className="w-full"
            onClick={handleOnSettingsClick}
          >
            {t('settings.title')}
          </Button>

          <Button
            mode="primary-outline"
            className="w-full"
            icon={<LogOut01 className="h-5 w-5 rounded-full" />}
            btnClassName="bg-incorrect-gradient hover:bg-incorrect-hover-gradient px-1 text-nowrap text-neutral50"
            onClick={handleOnLogoutClick}
          >
            {t('logout.title')}
          </Button>
        </div>

        <LoaderWrapper loading={loading || refetching} error={!!error} showChildrenOnLoading={!!studentProfile}>
          <div className="flex w-full flex-col gap-1 p-4 py-2">
            <span className="font-semibold text-neutral100">{t('classrooms.title')}</span>
            <div
              style={{
                background:
                  'linear-gradient(132deg, rgba(195, 189, 255, 0.23) -12.4%, rgba(245, 244, 255, 0.23) 93.7%)',
                backdropFilter: 'blur(14px)'
              }}
              className="flex w-full flex-col rounded-2xl"
            >
              {classrooms && classrooms.length > 0 ? (
                classrooms.map((classroom) => {
                  const LanguageFlag = subjectToFlagRoundIcon[classroom.subject] || subjectToFlagRoundIcon['ENGLISH'] // default value
                  const fullObjectName = classroom?.name + ` - ${t(`subject.${classroom.subject}`)}`

                  return (
                    <div key={classroom.id} className="flex h-14 w-full flex-row items-center justify-between px-2">
                      <div className="flex flex-row items-center gap-3">
                        <div className="h-10 w-10 overflow-hidden rounded-full">
                          <LanguageFlag className="h-10 w-10" />
                        </div>
                        <span className="font-bold text-neutral50">{fullObjectName}</span>
                      </div>
                    </div>
                  )
                })
              ) : (
                <div className="flex h-14 w-full flex-row items-center justify-between px-2 pl-3">
                  <span className="text-neutral50">{t('settings.noClass')}</span>
                </div>
              )}
            </div>
          </div>
        </LoaderWrapper>

        <div className="flex w-full flex-col items-center gap-1 p-4">
          <span className="w-full text-left font-semibold text-neutral100">{t('feedbackLabel')}</span>
          <div className="flex w-full items-center justify-between">
            <Button
              icon={<img src={ImageContent.common.iconFoxino} className="size-6 rounded-full" />}
              mode="primary-outline"
              className="w-full"
              onClick={showExitFeedbackDialog}
            >
              {t('feedbackTitle')}
            </Button>
          </div>
        </div>
      </div>
    </SettingsLayout>
  )
}
