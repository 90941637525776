import { registerPlugin } from '@capacitor/core'

export type SpeechRecognitionPermission = 'granted' | 'denied' | 'undetermined'

export type SpeechRecognitionConfig = { region: string; subscriptionKey: string; recognizedCallback?: string }

export type SpeechRecognitionOptions = {
  language: string
  isWithPronunciation: boolean
  referenceText?: string
  phraseList: string[]
}

export type ListenerCallback = (
  { text, pronunciationJSON, recognizing }: { text: string; pronunciationJSON?: string; recognizing?: boolean },
  err?: any
) => void

export interface SpeechRecognitionInterface {
  start(options: SpeechRecognitionOptions, callback: ListenerCallback): Promise<string>
  stop(): Promise<void>
  initConfig(options: SpeechRecognitionConfig): Promise<void>
  getSupportedLanguages(): Promise<{ languages: string[] }>
  hasPermission(): Promise<{ permission: SpeechRecognitionPermission }>
  requestPermission(): Promise<{ permission: SpeechRecognitionPermission }>
  clearListeners(): Promise<void>
}

const SpeechRecognitionPlugin = registerPlugin<SpeechRecognitionInterface>('SpeechRecognitionPlugin')

export { SpeechRecognitionPlugin }
