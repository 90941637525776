import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useToggle, useWindowSize } from 'react-use'
import { interpolate, motion, useMotionValue, useTransform } from 'framer-motion'
import Lottie from 'lottie-react'

import { LottieContent } from '@app/assets/LottieContent'
import { LoaderWrapper } from '@app/components'
import { Pages } from '@app/config/router/Pages'
import { StudentRank } from '@app/data'
import { useTranslation } from '@app/locales'
import { MainHeader, NoClassSection } from '@app/modules/common'
import { useDashboardMenuHeight } from '@app/modules/common/layout/DashboardLayout/useDashboardMenuHeight'
import { localSettingsStore } from '@app/modules/common/store/localSettingsStore'
import { useStudentInfo } from '@app/modules/tasks/data'
import { isCurrentPathActive, showToast } from '@app/utils/commonUtils'
import { logError } from '@app/utils/logsUtils'
import { getSafeAreaInset, isTablet } from '@app/utils/platformUtils'

import { useStudentsRanks } from '../../data/useStudentsRanks'
import { RanksDataType } from '../../model/enums'
import { Banner, BANNER_HEIGHT } from './components/Banner'
import { CurrentPlayerItemOverlay } from './components/CurrentPlayerItemOverlay'
import { DataTypeSwitch, SWITCH_HEIGHT } from './components/DataTypeSwitch'
import { PLAYER_ITEM_HEIGHT } from './components/PlayerItem'
import { RestPlayersList } from './components/RestPlayersList'
import { MEDAL_AVATAR_HEIGHT, MEDAL_SECTION_HEIGHT, TopPlayers } from './components/TopPlayers'

export const LeaderBoardPage = () => {
  const [isReady, setIsReady] = useState(false)
  const [dataType, setDataType] = useState<RanksDataType>(RanksDataType.WEEK)

  const [isSwitchClassBannerVisible, toggleSwitchClassBanner] = useToggle(false)
  const [isSwipingTransitionInProgress, toggleSwipingTransitionInProgress] = useToggle(false)
  const [isCurrentPlayerItemVisibleInWeekView, toggleCurrentPlayerItemWeekView] = useToggle(false)
  const [isCurrentPlayerItemVisibleInMonthView, toggleCurrentPlayerItemMonthView] = useToggle(false)
  const [isCurrentPlayerItemVisibleInOverallView, toggleCurrentPlayerItemOverallView] = useToggle(false)

  const { t } = useTranslation('dashboard')
  const { pathname } = useLocation()
  const classroomId = localSettingsStore.useStore((state) => state.classroomId)

  const { studentInfo, loading: loadingStudentInfo, refetching: refetchingStudentInfo } = useStudentInfo()

  const {
    overallRanks: studentOverallRanks,
    weekRanks: studentWeekRanks,
    monthRanks: studentMonthRanks,
    error: errorRanks,
    refetch: refetchRanks,
    loading: loadingRanks,
    refetching: refetchingRanks
  } = useStudentsRanks(classroomId)

  // only top 3 players are shown in the top section
  const topPlayersForWeek: StudentRank[] = studentWeekRanks?.slice(0, 3) as StudentRank[]
  const topPlayersForMonth: StudentRank[] = studentMonthRanks?.slice(0, 3) as StudentRank[]
  const topPlayersOverall: StudentRank[] = studentOverallRanks?.slice(0, 3) as StudentRank[]
  const topPlayersToUse =
    dataType === RanksDataType.WEEK
      ? topPlayersForWeek
      : dataType === RanksDataType.MONTH
        ? topPlayersForMonth
        : topPlayersOverall

  // the rest of the players are shown in the bottom section
  const restPlayersForWeek: StudentRank[] = studentWeekRanks?.slice(3) as StudentRank[]
  const restPlayersForMonth: StudentRank[] = studentMonthRanks?.slice(3) as StudentRank[]
  const restPlayersOverall: StudentRank[] = studentOverallRanks?.slice(3) as StudentRank[]
  const restPlayersToUse =
    dataType === RanksDataType.WEEK
      ? restPlayersForWeek
      : dataType === RanksDataType.MONTH
        ? restPlayersForMonth
        : restPlayersOverall

  const { height: windowHeight } = useWindowSize()
  const bottomBarHeight = useDashboardMenuHeight()

  const safeAreaBottom = getSafeAreaInset()

  const getBottomPosition = interpolate([400, 680, 844, 1024], [-160, 0, 64, 160])
  const getVisiblePlayers = interpolate([640, 680, 844, 1024], [1, 2, 3, 4])

  // the visible capacity
  const numOfPossiblePlayerItem = isTablet() ? 3 : Math.floor(getVisiblePlayers(windowHeight))
  // the number of items we want to show
  const numOfVisibleRestPlayers = Math.min(restPlayersToUse.length, numOfPossiblePlayerItem)
  // how many items exceed the visible capacity
  const extraRestPlayersItems = restPlayersToUse.length - numOfVisibleRestPlayers

  // calculate the Y coordinate of the "RestPlayersList"
  const initialRestPlayersListDragY =
    windowHeight - bottomBarHeight - safeAreaBottom.bottom - PLAYER_ITEM_HEIGHT * numOfVisibleRestPlayers - 8

  const dataTypeSwitchOpacity = useMotionValue(1)
  const bannerOpacity = useMotionValue(1)
  const bannerY = useMotionValue(0)
  const topPlayersY = useMotionValue(0)
  const medalColumnHeight = useMotionValue(MEDAL_SECTION_HEIGHT)
  const medalAvatarHeight = useMotionValue(MEDAL_AVATAR_HEIGHT)
  const medalAvatarScale = useMotionValue(1)
  const restPlayersListDragY = useMotionValue(initialRestPlayersListDragY)

  /*
   * The `restPlayersListDragConstraints` object defines how far the "RestPlayersList" can be dragged vertically.
   * It has two properties:
   * 1. `top`: The upper limit of the vertical drag. If there are extra items that exceed the visible space (`extraRestPlayersItems > 0`),
   *    we calculate a negative top value to allow the list to be dragged up, revealing more items.
   *    If there are no extra items (`extraRestPlayersItems <= 0`), `top` remains at 0,
   *    meaning we can't drag the list upward since all items are already visible.
   *
   * 2. `bottom`: The lower limit of the vertical drag, set to `initialRestPlayersListDragY`.
   *    This ensures that the list cannot be dragged down beyond its initial displayed position.
   */
  const restPlayersListDragConstraints = {
    top: extraRestPlayersItems > 0 ? initialRestPlayersListDragY - (extraRestPlayersItems * PLAYER_ITEM_HEIGHT + 8) : 0,
    bottom: initialRestPlayersListDragY
  }

  const medalAvatarScaleMapping = useTransform(
    restPlayersListDragY,
    // map restPlayersListDragY from these values:
    [initialRestPlayersListDragY - 200, initialRestPlayersListDragY], // 200 is small offset to make the animation happen sooner
    // into these scale values:
    [0.75, 1]
  )

  function onRestPlayersListDragYUpdate(latest: number) {
    if (latest > initialRestPlayersListDragY) {
      // dragging down will "stretch out" the TopPlayers section
      const offset = latest - initialRestPlayersListDragY
      medalColumnHeight.set(MEDAL_SECTION_HEIGHT + offset)
      medalAvatarHeight.set(MEDAL_AVATAR_HEIGHT + offset / 2) // move the medalAvatarHeight by 1/2 velocity
    } else if (latest < initialRestPlayersListDragY) {
      // dragging up will move the TopPlayers up
      const offset = initialRestPlayersListDragY - latest
      if (offset < 0) {
        medalColumnHeight.set(MEDAL_SECTION_HEIGHT - offset)
        medalAvatarHeight.set(MEDAL_AVATAR_HEIGHT - offset / 2) // move the medalAvatarHeight by 1/2 velocity
      } else {
        topPlayersY.set(-offset / 4) // move the topPlayersY by 1/4 velocity
      }

      // update DataTypeSwitch + Banner opacity when the list is scrolling up
      dataTypeSwitchOpacity.set((latest - SWITCH_HEIGHT) / 100)
      bannerOpacity.set((latest - SWITCH_HEIGHT - BANNER_HEIGHT - 120) / 100) // 96px is small padding between Banner and TopPlayers
      bannerY.set(-offset / 20) // move the bannerY by 1/20 velocity
    }
  }

  // change the medalAvatarScale to update the avatar size when the list is scrolling up
  // this approach does not disrupt the avatar's initial scaling animation
  function updateMedalAvatarScale(latest: number) {
    medalAvatarScale.set(latest)
  }

  useEffect(() => {
    const unsubscribeRestPlayersListDragY = restPlayersListDragY.on('change', onRestPlayersListDragYUpdate)
    const unsubscribeScaleMapping = medalAvatarScaleMapping.on('change', updateMedalAvatarScale)

    return () => {
      unsubscribeRestPlayersListDragY()
      unsubscribeScaleMapping()
    }
  }, [])

  useEffect(() => {
    if (errorRanks) {
      logError(errorRanks, 'RanksScreen', 'useEffect')
      showToast(t('errorGlobal'), { type: 'error', toastId: 'RanksScreen' })
    }
  }, [errorRanks])

  useEffect(() => {
    if (isCurrentPathActive(pathname, Pages.DASHBOARD_LEADER_BOARD, true) && classroomId) {
      refetchRanks()
    }
  }, [pathname])

  const handleOnAnimationComplete = () => {
    setIsReady(true)
  }

  const loading = loadingRanks || refetchingRanks || loadingStudentInfo || refetchingStudentInfo
  const currentPlayerOverlayTop = initialRestPlayersListDragY + (numOfVisibleRestPlayers - 1) * PLAYER_ITEM_HEIGHT + 4

  return (
    <div
      style={{
        paddingTop: 'env(safe-area-inset-top)'
      }}
      className="relative flex h-full w-full flex-col items-center overflow-hidden overscroll-none"
    >
      <motion.div style={{ opacity: dataTypeSwitchOpacity }} className="w-full">
        <MainHeader
          isSwitchClassEnable
          isSwitchClassBannerVisible={isSwitchClassBannerVisible}
          toggleSwitchClassBanner={toggleSwitchClassBanner}
        />
      </motion.div>

      {!loading && (!studentInfo?.student.classrooms || studentInfo?.student.classrooms.length === 0) ? (
        <NoClassSection />
      ) : (
        <>
          {isSwitchClassBannerVisible && (
            <div className="fixed inset-0 top-[env(safe-area-inset-top)] z-[98] bg-black/50 backdrop-blur" />
          )}

          <Lottie
            className="absolute bottom-0 left-0 h-full w-[529px] opacity-30"
            animationData={LottieContent.celebration.Confetti}
          />
          <Lottie
            className="absolute bottom-0 right-0 h-full w-[529px] opacity-30"
            animationData={LottieContent.celebration.Confetti}
          />

          <motion.div
            style={{ height: SWITCH_HEIGHT, opacity: dataTypeSwitchOpacity }}
            className="absolute z-[60] w-full"
          >
            <DataTypeSwitch dataType={dataType} setDataType={setDataType} />
          </motion.div>

          <motion.div
            style={{
              top: `calc(env(safe-area-inset-top) + ${SWITCH_HEIGHT}px)`,
              height: BANNER_HEIGHT,
              opacity: bannerOpacity,
              y: bannerY
            }}
            className="absolute z-10 w-full"
          >
            <Banner />
          </motion.div>

          <LoaderWrapper loading={loading} showChildrenOnLoading={false}>
            <motion.div
              style={{
                y: topPlayersY,
                bottom: `calc(env(safe-area-inset-bottom) + ${bottomBarHeight}px + ${getBottomPosition(windowHeight)}px)`,
                height: MEDAL_SECTION_HEIGHT + MEDAL_AVATAR_HEIGHT,
                scale: windowHeight < 640 ? 0.7 : 1 // make this visible for very small screens like iPhone SE
              }}
              className="absolute w-full max-w-[480px]"
            >
              {topPlayersToUse.length > 0 && (
                <TopPlayers
                  isReady={isReady}
                  players={topPlayersToUse}
                  onAnimationComplete={handleOnAnimationComplete}
                  medalAvatarScale={medalAvatarScale}
                  medalAvatarHeight={medalAvatarHeight}
                  medalColumnHeight={medalColumnHeight}
                />
              )}
            </motion.div>

            {/* when numOfVisibleRestPlayers is 1 there is simply not enough space to show the CurrentPlayerItemOverlay, so we just hide it */}
            {!isSwipingTransitionInProgress || numOfVisibleRestPlayers > 1 ? (
              <>
                {dataType === RanksDataType.WEEK && !isCurrentPlayerItemVisibleInWeekView && (
                  <CurrentPlayerItemOverlay
                    key="week"
                    player={restPlayersForWeek.find((item) => item.currentStudent)!}
                    index={restPlayersForWeek.findIndex((item) => item.currentStudent)}
                    top={currentPlayerOverlayTop}
                  />
                )}
                {dataType === RanksDataType.MONTH && !isCurrentPlayerItemVisibleInMonthView && (
                  <CurrentPlayerItemOverlay
                    key="month"
                    player={restPlayersForMonth.find((item) => item.currentStudent)!}
                    index={restPlayersForMonth.findIndex((item) => item.currentStudent)}
                    top={currentPlayerOverlayTop}
                  />
                )}
                {dataType === RanksDataType.OVERALL && !isCurrentPlayerItemVisibleInOverallView && (
                  <CurrentPlayerItemOverlay
                    key="overall"
                    player={restPlayersOverall.find((item) => item.currentStudent)!}
                    index={restPlayersOverall.findIndex((item) => item.currentStudent)}
                    top={currentPlayerOverlayTop}
                  />
                )}
              </>
            ) : null}

            {restPlayersToUse.length > 0 && (
              <motion.div
                className="absolute top-0 z-50 w-full max-w-screen-md rounded-t-2xl bg-btn-primary-outline-bg-gradient p-2"
                style={{ y: restPlayersListDragY }}
                transition={{ duration: 0.5, type: 'spring' }}
                drag={!isReady || restPlayersToUse.length <= numOfVisibleRestPlayers ? false : 'y'}
                dragConstraints={restPlayersListDragConstraints}
              >
                <RestPlayersList
                  dataType={dataType}
                  setDataType={setDataType}
                  playersForWeek={restPlayersForWeek}
                  playersForMonth={restPlayersForMonth}
                  playersOverall={restPlayersOverall}
                  onSlideChangeTransitionStart={() => toggleSwipingTransitionInProgress(true)}
                  onSlideChangeTransitionEnd={() => toggleSwipingTransitionInProgress(false)}
                  onCurrentStudentInWeekView={toggleCurrentPlayerItemWeekView}
                  onCurrentStudentInMonthView={toggleCurrentPlayerItemMonthView}
                  onCurrentStudentInOverallView={toggleCurrentPlayerItemOverallView}
                />
              </motion.div>
            )}
          </LoaderWrapper>
        </>
      )}
    </div>
  )
}
