export enum LessonValidationResult {
  NOT_VALIDATED = 'not_validated',
  SELECTED = 'selected',
  CORRECT = 'correct',
  ALMOST_CORRECT = 'almost_correct',
  INCORRECT = 'incorrect',
  VALIDATED = 'validated',
  HIDDEN = 'hidden'
}

export enum FlashCardHighlightMode {
  CORRECT = 'correct',
  INCORRECT = 'incorrect',
  SELECTED = 'selected',
  HIDDEN = 'hidden',
  NONE = 'none'
}

export enum AssignmentStage {
  INITIALIZING = 'INITIALIZING',
  WAITING_FOR_PRESENTATION = 'WAITING_FOR_PRESENTATION',
  PRESENTING = 'PRESENTING',
  WAITING_FOR_ANSWER = 'WAITING_FOR_ANSWER'
}

export enum ResponseStage {
  INITIALIZING = 'INITIALIZING',
  PREPARING_RECORDING = 'PREPARING_RECORDING',
  RECORDING = 'RECORDING',
  TRANSLATING = 'TRANSLATING',
  WAITING_FOR_INPUT = 'WAITING_FOR_INPUT',
  WAITING_FOR_VALIDATION = 'WAITING_FOR_VALIDATION',
  SHOWING_RESULTS = 'SHOWING_RESULTS'
}
