import { ImageType, Maybe } from '@app/data'

export type ImageSize = Exclude<keyof ImageType, '__typename'>

export const getImageUrlFromImageType = (
  imageType: Maybe<ImageType> | undefined,
  size: ImageSize = 'medium'
): string => {
  if (!imageType) {
    return ''
  }

  let finalImageUrl: Maybe<string> | undefined

  switch (size) {
    case 'large':
      finalImageUrl = imageType.large || imageType.medium
      break

    case 'small':
      finalImageUrl = imageType.small || imageType.medium
      break

    case 'medium':
      finalImageUrl = imageType.medium || imageType.large
      break

    case 'thumbnail':
      finalImageUrl = imageType.thumbnail || imageType.small
      break

    default:
      finalImageUrl = imageType.medium
  }

  return finalImageUrl || imageType.default
}
