import { forwardRef } from 'react'
import classNames from 'classnames'
import { motion } from 'framer-motion'

import { Label } from '@app/components'

type Props = {
  textInfo?: {
    from: number
    to: number
    separator?: string
  }
  progress: number
  colors?: {
    bg?: string
    fg?: string
    gradient?: {
      from: string
      to: string
    }
  }
  size?: number
  className?: string
  strokeWidth?: number
}

/**
 * @group Components
 * @category Component
 */
export const CircleProgressBar = forwardRef(
  (
    { className, progress, colors, textInfo, size = 20, strokeWidth = 3, ...restProps }: Props,
    ref: React.Ref<SVGSVGElement>
  ) => {
    const bgColor = colors?.bg || '#4D4693'
    const fgColor = colors?.fg || '#FFB627'
    const gradient = colors?.gradient
    const { from, to, separator } = textInfo || {}

    // Circle properties
    const radius = (size - strokeWidth) / 2
    const circumference = radius * 2 * Math.PI
    const offset = circumference - (progress / 100) * circumference - 0.1

    return (
      <div className={classNames('flex -rotate-90 items-center gap-2', className)}>
        <svg width={size} height={size} ref={ref} {...restProps}>
          <defs>
            <linearGradient id={`${progress}`} x1="0%" y1="0%" x2="100%" y2="100%">
              <stop offset="0%" style={{ stopColor: gradient?.from, stopOpacity: 1 }} />
              <stop offset="100%" style={{ stopColor: gradient?.to, stopOpacity: 1 }} />
            </linearGradient>
          </defs>
          <circle
            stroke={bgColor}
            fill="transparent"
            strokeWidth={strokeWidth}
            r={radius}
            cx={size / 2}
            cy={size / 2}
          />
          <motion.circle
            stroke={gradient ? `url(#${progress})` : fgColor}
            fill="transparent"
            strokeWidth={strokeWidth}
            strokeDasharray={circumference + ' ' + circumference}
            initial={{ strokeDashoffset: circumference }}
            animate={{ strokeDashoffset: offset }}
            transition={{ duration: 1 }}
            strokeLinecap="round"
            r={radius}
            cx={size / 2}
            cy={size / 2}
          />
        </svg>
        {textInfo && (
          <Label size="s" weight="bold" className="text-nowrap">{`${from} ${separator || '/'} ${to}`}</Label>
        )}
      </div>
    )
  }
)

CircleProgressBar.displayName = 'CircleProgressBar'
