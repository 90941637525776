import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import axios, { AxiosError } from 'axios'

import { Pages } from '@app/config/router/Pages'
import Constants from '@app/constants'
import { logError } from '@app/utils/logsUtils'

import { MaintenanceStatus } from '../model/enums'

const STATUS_TIMEOUT = 8_000
const TIMEOUT_ERROR = 'ECONNABORTED'

export const useCheckMaintenance = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [isMaintenance, setIsMaintenance] = useState<boolean | undefined>(undefined)
  const [lastMaintenanceTimestamp, setLastMaintenanceTimestamp] = useState<number>(
    Date.now() - Constants.MAINTENANCE_CHECK_INTERVAL
  )

  useEffect(() => {
    const shouldCheckMaintenance = Date.now() - lastMaintenanceTimestamp > Constants.MAINTENANCE_CHECK_INTERVAL

    if (isMaintenance || shouldCheckMaintenance) {
      checkMaintenanceStatus()
    }
  }, [location.pathname])

  const checkMaintenanceStatus = async () => {
    setLastMaintenanceTimestamp(Date.now())

    try {
      const { data } = await axios.get<{ status: MaintenanceStatus }>(Constants.MAINTENANCE_URL, {
        timeout: STATUS_TIMEOUT
      })

      console.info('[MAINTENANCE] status:', data.status)
      if (data.status === MaintenanceStatus.MAINTENANCE) {
        setIsMaintenance(true)
        if (location.pathname !== Pages.MAINTENANCE && navigator.onLine) {
          navigate(Pages.MAINTENANCE)
        }
      } else if (data.status === MaintenanceStatus.UP) {
        setIsMaintenance(false)
        if (location.pathname === Pages.MAINTENANCE) {
          navigate(Pages.ROOT)
        }
      }
    } catch (error) {
      if (error instanceof AxiosError && error.code === TIMEOUT_ERROR) {
        console.info('[checkMaintenanceStatus] Fetch request timed out')
      } else {
        logError(error, 'MainLayout', 'checkMaintenanceStatus', undefined, false)

        if (location.pathname !== Pages.MAINTENANCE) {
          navigate(Pages.MAINTENANCE)
        }
      }
    }
  }

  return { isMaintenance, checkMaintenanceStatus }
}
