import { Haptics, ImpactStyle } from '@capacitor/haptics'

const styleToDuration = {
  Light: 100,
  Medium: 300,
  Heavy: 500
}

export const hapticsImpact = async (style: keyof typeof ImpactStyle = 'Light') => {
  try {
    await Haptics.impact({ style: ImpactStyle[style] })
  } catch {
    try {
      await Haptics.vibrate({ duration: styleToDuration[style] })
    } catch {
      // do nothing
    }
  }
}

export const hapticsImpactHeavy = async () => {
  try {
    await Haptics.impact({ style: ImpactStyle.Heavy })
  } catch {
    try {
      await Haptics.vibrate({ duration: 500 })
    } catch {
      // do nothing
    }
  }
}

export const hapticsImpactMedium = async () => {
  try {
    await Haptics.impact({ style: ImpactStyle.Medium })
  } catch {
    try {
      await Haptics.vibrate({ duration: 300 })
    } catch {
      // do nothing
    }
  }
}

export const hapticsImpactLight = async () => {
  try {
    await Haptics.impact({ style: ImpactStyle.Light })
  } catch {
    try {
      await Haptics.vibrate({ duration: 100 })
    } catch {
      // do nothing
    }
  }
}

export const hapticsVibrate = async () => {
  try {
    await Haptics.vibrate()
  } catch {
    // do nothing
  }
}

export const hapticsSelectionStart = async () => {
  try {
    await Haptics.selectionStart()
  } catch {
    // do nothing
  }
}

export const hapticsSelectionChanged = async () => {
  try {
    await Haptics.selectionChanged()
  } catch {
    // do nothing
  }
}

export const hapticsSelectionEnd = async () => {
  try {
    await Haptics.selectionEnd()
  } catch {
    // do nothing
  }
}
