import {
  isAndroid as deviceAndroid,
  isChrome as deviceChrome,
  isFirefox as deviceFirefox,
  isIOS as deviceIOS,
  isMobile as deviceMobile,
  isMobileOnly as deviceMobileOnly,
  isSafari as deviceSafari,
  isTablet as deviceTablet
} from 'react-device-detect'
import { Capacitor } from '@capacitor/core'

// returns true if device type is browser
export const isBrowser = () => {
  return isChrome() || isFirefox() || isSafari()
}

// returns true if browser is Chrome
export const isChrome = () => {
  return deviceChrome
}

// returns true if browser is Firefox
export const isFirefox = () => {
  return deviceFirefox
}

// returns true if browser is Safari
export const isSafari = () => {
  return deviceSafari
}

// returns true if device type is mobile or tablet
export const isMobileOrTablet = () => {
  return deviceMobile
}

// returns true if device type is mobile
export const isMobile = () => {
  return deviceMobileOnly
}

// returns true if device type is tablet
export const isTablet = () => {
  return deviceTablet
}

// it could be IOS web browser or IOS native app
export const isIOS = () => {
  return deviceIOS
}

// it could be Android web browser or Android native app
export const isAndroid = () => {
  return deviceAndroid
}

export const isNative = () => {
  return Capacitor.isNativePlatform()
}

export const isNativeIOS = () => {
  return Capacitor.getPlatform() === 'ios'
}

export const isNativeAndroid = () => {
  return Capacitor.getPlatform() === 'android'
}

export const getSafeAreaInset = () => {
  return {
    top: parseInt(getComputedStyle(document.documentElement).getPropertyValue('--sat').split('px')[0]),
    bottom: parseInt(getComputedStyle(document.documentElement).getPropertyValue('--sab').split('px')[0]),
    left: parseInt(getComputedStyle(document.documentElement).getPropertyValue('--sal').split('px')[0]),
    right: parseInt(getComputedStyle(document.documentElement).getPropertyValue('--sar').split('px')[0])
  }
}
