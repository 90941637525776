import { useAudio } from 'react-use'

import { LearningFeatureNew } from '@app/data'
import { useTts } from '@app/hooks'
import { TranslationType } from '@app/modules/common/model/enums'

type Props = {
  lf: LearningFeatureNew
}

export const PackageDetailAssignment = ({ lf }: Props) => {
  const imageSrc = lf?.images?.[0]?.small || ''

  // TTS will be use as backup solution for playing audio
  const { speak } = useTts(lf)

  const [audioObject, soundState, soundControls] = useAudio({
    src: lf?.targetSoundUrls?.[0] || ''
  })

  const playAudio = async () => {
    if (lf?.targetSoundUrls?.[0] && soundState.duration) {
      await soundControls.play()
    } else {
      speak(TranslationType.TARGET)
    }
  }

  return (
    <div className="flex min-h-20 w-full items-center justify-between rounded-xl bg-[#5A47B2] px-4 py-2">
      <div className="flex flex-col items-start justify-center gap-2">
        <span className="text-[18px] font-bold text-white">{lf.targetLanguageText}</span>
        <span className="text-[14px] text-white">{lf.sourceLanguageText}</span>
      </div>

      <div className="relative flex flex-row items-center justify-between gap-2">
        {imageSrc && <img className="h-14 w-14" src={imageSrc} alt="assignment" />}

        {audioObject}

        <div
          className="flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-[#FF9A51] hover:bg-[#FC4C00]"
          onClick={playAudio}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
              d="M21 10.2679C22.3333 11.0377 22.3333 12.9623 21 13.7321L9 20.6603C7.66667 21.4301 6 20.4678 6 18.9282L6 5.0718C6 3.5322 7.66667 2.56995 9 3.33975L21 10.2679Z"
              fill="white"
            />
          </svg>
        </div>
      </div>
    </div>
  )
}
