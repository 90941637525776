import { motion } from 'framer-motion'

import { profilePicturesContent } from '@foxino/components-common'

import { useTranslation } from '@app/locales'
import { getFullName } from '@app/modules/auth/utils/getFullName'

import { PLAYER_ITEM_HEIGHT, PlayerItemProps } from './PlayerItem'

type CurrentPlayerItemOverlayProps = PlayerItemProps & {
  top: number
}

export const CurrentPlayerItemOverlay = ({ player, index, top }: CurrentPlayerItemOverlayProps) => {
  const { t } = useTranslation('common')

  if (index === -1) {
    return null
  }

  const { backgroundColor, image } = profilePicturesContent[player?.student.userProfile.pictureUrl || 'image1']

  return (
    <motion.div
      style={{
        height: PLAYER_ITEM_HEIGHT,
        top
      }}
      className="absolute z-[100] mx-4 flex w-[calc(100%-1rem)] max-w-[calc(768px-1rem)] flex-row justify-between rounded-2xl bg-[#bba4ff] px-2 text-right text-base font-extrabold text-[#7164E9] antialiased shadow-card"
    >
      <div className="flex flex-row items-center gap-2">
        <span className="w-[24px]">{index + 4}.</span>
        <div className="h-10 w-10 rounded-full p-1" style={{ backgroundColor }}>
          <img className="pointer-events-none h-full w-full" src={image} />
        </div>
        <span>{getFullName(player?.student.userProfile.firstName, player?.student.userProfile.lastName)}</span>
      </div>

      <div className="flex flex-row items-center justify-center gap-1 text-lg">
        {(player?.points || 0).toLocaleString()} {t('point')}
      </div>
    </motion.div>
  )
}
