import { useCallback } from 'react'

import { StudentLibraryOverviewDocument, useUpdateIsFavoriteFlagMutation } from '@app/data'
import { logError } from '@app/utils/logsUtils'

interface UseFavoriteToggleHandlerParams {
  id: string | undefined
  isFavoriteFlag: boolean
  toggleIsFavoriteFlag: () => void
}

export function useFavoriteToggleHandler({ id, isFavoriteFlag, toggleIsFavoriteFlag }: UseFavoriteToggleHandlerParams) {
  const [updateIsFavoriteFlag] = useUpdateIsFavoriteFlagMutation()

  const handleOnFavoriteClick = useCallback(async () => {
    try {
      if (!id) {
        return
      }

      await updateIsFavoriteFlag({
        variables: {
          subPackageId: id,
          input: { isFavorite: !isFavoriteFlag }
        },
        refetchQueries: [StudentLibraryOverviewDocument]
      })

      toggleIsFavoriteFlag()
    } catch (error) {
      logError(error, 'useFavoriteToggleHandler', 'handleOnFavoriteClick')
    }
  }, [id, isFavoriteFlag])

  return { handleOnFavoriteClick }
}
