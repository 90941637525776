import { StudentLibraryImageBackground } from '@app/data'

export const getBgColor = (bg: StudentLibraryImageBackground) => {
  switch (bg) {
    case StudentLibraryImageBackground.BEIGE:
      return '#D1CAB2'
    case StudentLibraryImageBackground.BLUE:
      return '#7DA0E9'
    case StudentLibraryImageBackground.GREEN:
      return '#B2D994'
    case StudentLibraryImageBackground.LIGHT_BLUE:
      return '#8ED0E9'
    case StudentLibraryImageBackground.MINT:
      return '#7EDAD2'
    case StudentLibraryImageBackground.ORANGE:
      return '#EC9A71'
    case StudentLibraryImageBackground.PINK:
      return '#FF8FA8'
    case StudentLibraryImageBackground.PURPLE:
      return '#9E85D9'
    case StudentLibraryImageBackground.SALMON:
      return '#FF9D9D'
    case StudentLibraryImageBackground.YELLOW:
      return '#E1CD8E'
  }
}
