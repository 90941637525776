import { EmojiContent } from '@app/assets/EmojiContent'
import { ActivityResult, FlashCardAssignmentType, FlashCardResponseType, FullScreenFlashCardType } from '@app/data'
import i18n from '@app/locales/i18n'

import { LessonValidationResult } from './enums'

export const ActivityResultToValidationResult: Record<ActivityResult, LessonValidationResult> = {
  ALMOST_CORRECT: LessonValidationResult.ALMOST_CORRECT,
  CORRECT: LessonValidationResult.CORRECT,
  NOT_INVOLVED: LessonValidationResult.INCORRECT,
  SKIPPED: LessonValidationResult.INCORRECT,
  WRONG: LessonValidationResult.INCORRECT
}

// For developer mode
export const flashcardCombinations: Record<
  FullScreenFlashCardType | `${FlashCardAssignmentType}/${FlashCardResponseType}`,
  Partial<{
    fullscreenType: FullScreenFlashCardType
    assignmentType: FlashCardAssignmentType
    responseType: FlashCardResponseType
    isDisabled: boolean
  }>
> = {
  // Image assignment types
  [`${FlashCardAssignmentType.IMAGE}/${FlashCardResponseType.TEXT}`]: {
    fullscreenType: undefined,
    assignmentType: FlashCardAssignmentType.IMAGE,
    responseType: FlashCardResponseType.TEXT,
    isDisabled: true
  },
  [`${FlashCardAssignmentType.IMAGE}/${FlashCardResponseType.SOUND}`]: {
    fullscreenType: undefined,
    assignmentType: FlashCardAssignmentType.IMAGE,
    responseType: FlashCardResponseType.SOUND,
    isDisabled: false
  },
  [`${FlashCardAssignmentType.IMAGE}/${FlashCardResponseType.CHOICE_IMAGE}`]: {
    fullscreenType: undefined,
    assignmentType: FlashCardAssignmentType.IMAGE,
    responseType: FlashCardResponseType.CHOICE_IMAGE,
    isDisabled: false
  },
  [`${FlashCardAssignmentType.IMAGE}/${FlashCardResponseType.CHOICE_TEXT}`]: {
    fullscreenType: undefined,
    assignmentType: FlashCardAssignmentType.IMAGE,
    responseType: FlashCardResponseType.CHOICE_TEXT,
    isDisabled: false
  },
  [`${FlashCardAssignmentType.IMAGE}/${FlashCardResponseType.SCRABBLE}`]: {
    fullscreenType: undefined,
    assignmentType: FlashCardAssignmentType.IMAGE,
    responseType: FlashCardResponseType.SCRABBLE,
    isDisabled: false
  },
  [`${FlashCardAssignmentType.IMAGE}/${FlashCardResponseType.WORD_PICKING}`]: {
    fullscreenType: undefined,
    assignmentType: FlashCardAssignmentType.IMAGE,
    responseType: FlashCardResponseType.WORD_PICKING,
    isDisabled: false
  },
  // Text assignment types
  [`${FlashCardAssignmentType.TEXT}/${FlashCardResponseType.TEXT}`]: {
    fullscreenType: undefined,
    assignmentType: FlashCardAssignmentType.IMAGE,
    responseType: FlashCardResponseType.TEXT,
    isDisabled: true
  },
  [`${FlashCardAssignmentType.TEXT}/${FlashCardResponseType.SOUND}`]: {
    fullscreenType: undefined,
    assignmentType: FlashCardAssignmentType.TEXT,
    responseType: FlashCardResponseType.SOUND,
    isDisabled: false
  },
  [`${FlashCardAssignmentType.TEXT}/${FlashCardResponseType.CHOICE_IMAGE}`]: {
    fullscreenType: undefined,
    assignmentType: FlashCardAssignmentType.TEXT,
    responseType: FlashCardResponseType.CHOICE_IMAGE,
    isDisabled: false
  },
  [`${FlashCardAssignmentType.TEXT}/${FlashCardResponseType.CHOICE_TEXT}`]: {
    fullscreenType: undefined,
    assignmentType: FlashCardAssignmentType.TEXT,
    responseType: FlashCardResponseType.CHOICE_TEXT,
    isDisabled: false
  },
  [`${FlashCardAssignmentType.TEXT}/${FlashCardResponseType.SCRABBLE}`]: {
    fullscreenType: undefined,
    assignmentType: FlashCardAssignmentType.TEXT,
    responseType: FlashCardResponseType.SCRABBLE,
    isDisabled: false
  },
  [`${FlashCardAssignmentType.TEXT}/${FlashCardResponseType.WORD_PICKING}`]: {
    fullscreenType: undefined,
    assignmentType: FlashCardAssignmentType.TEXT,
    responseType: FlashCardResponseType.WORD_PICKING,
    isDisabled: false
  },
  // Sound assignment types
  [`${FlashCardAssignmentType.SOUND}/${FlashCardResponseType.TEXT}`]: {
    fullscreenType: undefined,
    assignmentType: FlashCardAssignmentType.SOUND,
    responseType: FlashCardResponseType.TEXT,
    isDisabled: true
  },
  [`${FlashCardAssignmentType.SOUND}/${FlashCardResponseType.SOUND}`]: {
    fullscreenType: undefined,
    assignmentType: FlashCardAssignmentType.SOUND,
    responseType: FlashCardResponseType.SOUND,
    isDisabled: false
  },
  [`${FlashCardAssignmentType.SOUND}/${FlashCardResponseType.CHOICE_IMAGE}`]: {
    fullscreenType: undefined,
    assignmentType: FlashCardAssignmentType.SOUND,
    responseType: FlashCardResponseType.CHOICE_IMAGE,
    isDisabled: false
  },
  [`${FlashCardAssignmentType.SOUND}/${FlashCardResponseType.CHOICE_TEXT}`]: {
    fullscreenType: undefined,
    assignmentType: FlashCardAssignmentType.SOUND,
    responseType: FlashCardResponseType.CHOICE_TEXT,
    isDisabled: false
  },
  [`${FlashCardAssignmentType.SOUND}/${FlashCardResponseType.SCRABBLE}`]: {
    fullscreenType: undefined,
    assignmentType: FlashCardAssignmentType.SOUND,
    responseType: FlashCardResponseType.SCRABBLE,
    isDisabled: false
  },
  [`${FlashCardAssignmentType.SOUND}/${FlashCardResponseType.WORD_PICKING}`]: {
    fullscreenType: undefined,
    assignmentType: FlashCardAssignmentType.SOUND,
    responseType: FlashCardResponseType.WORD_PICKING,
    isDisabled: false
  },
  // Video assignment types
  [`${FlashCardAssignmentType.VIDEO}/${FlashCardResponseType.TEXT}`]: {
    fullscreenType: undefined,
    assignmentType: FlashCardAssignmentType.VIDEO,
    responseType: FlashCardResponseType.TEXT,
    isDisabled: true
  },
  [`${FlashCardAssignmentType.VIDEO}/${FlashCardResponseType.SOUND}`]: {
    fullscreenType: undefined,
    assignmentType: FlashCardAssignmentType.VIDEO,
    responseType: FlashCardResponseType.SOUND,
    isDisabled: false
  },
  [`${FlashCardAssignmentType.VIDEO}/${FlashCardResponseType.CHOICE_IMAGE}`]: {
    fullscreenType: undefined,
    assignmentType: FlashCardAssignmentType.VIDEO,
    responseType: FlashCardResponseType.CHOICE_IMAGE,
    isDisabled: false
  },
  [`${FlashCardAssignmentType.VIDEO}/${FlashCardResponseType.CHOICE_TEXT}`]: {
    fullscreenType: undefined,
    assignmentType: FlashCardAssignmentType.VIDEO,
    responseType: FlashCardResponseType.CHOICE_TEXT,
    isDisabled: false
  },
  [`${FlashCardAssignmentType.VIDEO}/${FlashCardResponseType.SCRABBLE}`]: {
    fullscreenType: undefined,
    assignmentType: FlashCardAssignmentType.VIDEO,
    responseType: FlashCardResponseType.SCRABBLE,
    isDisabled: false
  },
  [`${FlashCardAssignmentType.VIDEO}/${FlashCardResponseType.WORD_PICKING}`]: {
    fullscreenType: undefined,
    assignmentType: FlashCardAssignmentType.TEXT,
    responseType: FlashCardResponseType.WORD_PICKING,
    isDisabled: false
  },
  [FullScreenFlashCardType.SHOPPING_CART]: {
    fullscreenType: undefined,
    assignmentType: undefined,
    responseType: undefined,
    isDisabled: true
  },
  [FullScreenFlashCardType.SWIPE_MATCH]: {
    fullscreenType: undefined,
    assignmentType: undefined,
    responseType: undefined,
    isDisabled: true
  },
  [FullScreenFlashCardType.SOUND_TO_TEXT_CONNECTION]: {
    fullscreenType: FullScreenFlashCardType.SOUND_TO_TEXT_CONNECTION,
    assignmentType: undefined,
    responseType: undefined,
    isDisabled: false
  },
  [FullScreenFlashCardType.TEXT_TO_IMAGE_CONNECTION]: {
    fullscreenType: FullScreenFlashCardType.TEXT_TO_IMAGE_CONNECTION,
    assignmentType: undefined,
    responseType: undefined,
    isDisabled: false
  },
  [FullScreenFlashCardType.TEXT_TO_TEXT_CONNECTION]: {
    fullscreenType: FullScreenFlashCardType.TEXT_TO_TEXT_CONNECTION,
    assignmentType: undefined,
    responseType: undefined,
    isDisabled: false
  },
  [FullScreenFlashCardType.WHEEL_OF_FORTUNE_IMAGE]: {
    fullscreenType: FullScreenFlashCardType.WHEEL_OF_FORTUNE_IMAGE,
    assignmentType: undefined,
    responseType: undefined,
    isDisabled: false
  },
  [FullScreenFlashCardType.WHEEL_OF_FORTUNE_TEXT]: {
    fullscreenType: FullScreenFlashCardType.WHEEL_OF_FORTUNE_TEXT,
    assignmentType: undefined,
    responseType: undefined,
    isDisabled: false
  }
}

export const lessonValidationResultToTitle = {
  [LessonValidationResult.CORRECT]: i18n.t('training:lesson.result.correct.title'),
  [LessonValidationResult.ALMOST_CORRECT]: i18n.t('training:lesson.result.almostCorrect.title'),
  [LessonValidationResult.INCORRECT]: i18n.t('training:lesson.result.incorrect.title')
}

export const lessonValidationResultToDescription = {
  [LessonValidationResult.CORRECT]: {
    description1: {
      text: i18n.t('training:lesson.result.correct.description1'),
      emoji: EmojiContent.starStruck
    },
    description2: {
      text: i18n.t('training:lesson.result.correct.description2'),
      emoji: EmojiContent.smilingFaceWithHearts
    },
    description3: {
      text: i18n.t('training:lesson.result.correct.description3'),
      emoji: EmojiContent.nerdFace
    },
    description4: {
      text: i18n.t('training:lesson.result.correct.description4'),
      emoji: EmojiContent.beamingFaceWithSmilingEyes
    },
    description5: {
      text: i18n.t('training:lesson.result.correct.description5'),
      emoji: EmojiContent.smilingFaceWithHeartEyes
    },
    description6: {
      text: i18n.t('training:lesson.result.correct.description6'),
      emoji: EmojiContent.partyingFace
    },
    description7: {
      text: i18n.t('training:lesson.result.correct.description7'),
      emoji: EmojiContent.winkingFace
    }
  },
  [LessonValidationResult.ALMOST_CORRECT]: {
    description1: {
      text: i18n.t('training:lesson.result.almostCorrect.description1'),
      emoji: EmojiContent.smilingFaceWithSunglasses
    },
    description2: {
      text: i18n.t('training:lesson.result.almostCorrect.description2'),
      emoji: EmojiContent.disguisedFace
    },
    description3: {
      text: i18n.t('training:lesson.result.almostCorrect.description3'),
      emoji: EmojiContent.shushingFace
    },
    description4: {
      text: i18n.t('training:lesson.result.almostCorrect.description4'),
      emoji: EmojiContent.smirkingFace
    },
    description5: {
      text: i18n.t('training:lesson.result.almostCorrect.description5'),
      emoji: EmojiContent.upsideDownFace
    },
    description6: {
      text: i18n.t('training:lesson.result.almostCorrect.description6'),
      emoji: EmojiContent.astonishedFace
    },
    description7: {
      text: i18n.t('training:lesson.result.almostCorrect.description7'),
      emoji: EmojiContent.squintingFaceWithTongue
    }
  },
  [LessonValidationResult.INCORRECT]: {
    description1: {
      text: i18n.t('training:lesson.result.incorrect.description1'),
      emoji: EmojiContent.faceWithMonocle
    },
    description2: {
      text: i18n.t('training:lesson.result.incorrect.description2'),
      emoji: EmojiContent.zipperMouthFace
    },
    description3: {
      text: i18n.t('training:lesson.result.incorrect.description3'),
      emoji: EmojiContent.cowboyHatFace
    },
    description4: {
      text: i18n.t('training:lesson.result.incorrect.description4'),
      emoji: EmojiContent.faceInClouds
    },
    description5: {
      text: i18n.t('training:lesson.result.incorrect.description5'),
      emoji: EmojiContent.coldFace
    },
    description6: {
      text: i18n.t('training:lesson.result.incorrect.description6'),
      emoji: EmojiContent.woozyFace
    },
    description7: {
      text: i18n.t('training:lesson.result.incorrect.description7'),
      emoji: EmojiContent.pileOfPoo
    }
  }
}

export const validationResultToColor = {
  [LessonValidationResult.CORRECT]: '#23DB56',
  [LessonValidationResult.ALMOST_CORRECT]: '#FFB92F',
  [LessonValidationResult.INCORRECT]: '#FF555F',
  [LessonValidationResult.HIDDEN]: '#705AF8'
}
