import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Tool01 } from '@untitled-ui/icons-react'

import { Heading } from '@app/components'
import { Pages } from '@app/config/router/Pages'

import { useCheckMaintenance } from '../data/useCheckMaintenance'

const CHECK_INTERVAL = 20_000

export const MaintenancePage = () => {
  const { isMaintenance, checkMaintenanceStatus } = useCheckMaintenance()
  const navigate = useNavigate()

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null

    if (isMaintenance === false) {
      navigate(Pages.DASHBOARD)
    } else if (isMaintenance) {
      intervalId = setInterval(() => {
        checkMaintenanceStatus()
      }, CHECK_INTERVAL)
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId)
      }
    }
  }, [])

  return (
    <div className="absolute h-screen w-full overscroll-none bg-white">
      <div className="absolute top-[calc(env(safe-area-inset-top))] p-4">
        <Heading title="Maintenance" className="!text-black">
          Maintenance
        </Heading>
        <div className="flex h-screen w-screen bg-white">
          <div className="my-auto flex w-full flex-col items-center justify-center">
            <Heading size="m" className="flex items-center gap-2">
              <Tool01 /> We&apos;ll be back soon...
            </Heading>
            <img
              src="https://www.ontariovirtualschool.ca/scheduled_maintenance.gif"
              alt="Maintenance"
              className="mx-auto my-auto w-1/2"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
