import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

import { PronunciationInput } from '@app/data'

type CognitiveServicesStoreType = {
  isInitializing: boolean
  isRecognizing: boolean
  isRecognized: boolean
  recognizedText: string
  pronunciationAssessment?: PronunciationInput
  phraseList: string[] // a phraseList is a list of words or phrases provided ahead of time to help improve their recognition
}

const initialState: CognitiveServicesStoreType = {
  isInitializing: false,
  isRecognizing: false,
  isRecognized: false,
  recognizedText: '',
  phraseList: []
}

const useStore = createWithEqualityFn<CognitiveServicesStoreType>(() => initialState, shallow)

export const cognitiveServicesStore = {
  setIsRecognizing: (isRecognizing: boolean): void => {
    useStore.setState({ isRecognizing })
  },
  setIsInitializing: (isInitializing: boolean): void => {
    useStore.setState({ isInitializing })
  },
  setIsRecognized: (isRecognized: boolean): void => {
    useStore.setState({ isRecognized })
  },
  setRecognizedText: (recognizedText: string): void => {
    useStore.setState({ recognizedText })
  },
  setPronunciationAssessment: (pronunciationAssessment: PronunciationInput | undefined): void => {
    useStore.setState({ pronunciationAssessment })
  },
  addToPhraseList: (phrase: string, replace?: boolean): void => {
    useStore.setState((store) => ({
      phraseList: replace ? [phrase] : [...store.phraseList, phrase]
    }))
  },

  clearStore: (): void => {
    useStore.setState(initialState, true)
  },

  useStore
}
