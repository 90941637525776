import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useToggle } from 'react-use'
import { FreeMode } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import { useVisibleState } from '@foxino/components-common'

import { LoaderWrapper } from '@app/components'
import { Pages } from '@app/config/router/Pages'
import { useTranslation } from '@app/locales'
import { MainHeader } from '@app/modules/common'
import { localSettingsStore } from '@app/modules/common/store/localSettingsStore'
import { useProfileSettings } from '@app/modules/settings/data'
import { isCurrentPathActive } from '@app/utils/commonUtils'

import { InputSearch } from '../../components/InputSearch'
import { NoPackageSection } from '../../components/NoPackageSection'
import { StartSubPackageDialog } from '../../components/StartSubPackageDialog'
import { SubPackage } from '../../components/SubPackage'
import { useStudentLibraryOverview } from '../../data/useStudentLibraryOverview'

export const LibraryPage = () => {
  const { t } = useTranslation('library')
  const { pathname } = useLocation()

  const [searchQuery, setSearchQuery] = useState('')

  const [isSwitchClassBannerVisible, toggleSwitchClassBanner] = useToggle(false)
  const [isStartPackageDialogVisible, showStartPackageDialog, closeStartPackageDialog, subPackageId] = useVisibleState()

  const classroomId = localSettingsStore.useStore((state) => state.classroomId)

  const {
    studentProfile,
    // error: profileError,
    loading: profileLoading,
    refetching: profileRefetching
  } = useProfileSettings()
  const { classrooms } = studentProfile || {}

  const currentClassroom = classrooms?.find((classroom) => classroom.id === classroomId)

  const {
    overview,
    // error: overviewError,
    loading: overviewLoading,
    refetching: overviewRefetching,
    refetch: refetchOverview
  } = useStudentLibraryOverview({ skip: !currentClassroom?.subject, variables: { subject: currentClassroom?.subject } })

  useEffect(() => {
    if (currentClassroom) {
      refetchOverview()
    }
  }, [classroomId])

  useEffect(() => {
    if (isCurrentPathActive(pathname, Pages.DASHBOARD_LIBRARY, true) && !!overview) {
      refetchOverview()
    }
  }, [pathname])

  const handleSearch = (query) => {
    setSearchQuery(query)
  }

  const loading = profileLoading || profileRefetching || overviewLoading || overviewRefetching

  const progressPackages =
    overview && overview.inProgress && overview.inProgress.length > 0
      ? overview.inProgress.map((item) => ({ ...item }))
      : []
  const progressPackagesFiltered = searchQuery
    ? progressPackages.filter((item) => item.name.toLowerCase().includes(searchQuery.toLowerCase()))
    : progressPackages

  const otherPackages =
    overview && overview.packages && overview.packages.length > 0 ? overview.packages.map((item) => ({ ...item })) : []
  const otherPackagesFiltered = searchQuery
    ? otherPackages
        .map((pkg) => ({
          ...pkg,
          // for each package, filter its subPackages array down to just those subPackages whose names match searchQuery.
          subPackages: pkg.subPackages.filter((subPkg) =>
            subPkg?.name.toLowerCase().includes(searchQuery.toLowerCase())
          )
        }))
        // remove any packages that end up with no matching subPackages
        .filter((pkg) => pkg.subPackages.length > 0)
    : otherPackages

  return (
    <div
      style={{ paddingTop: 'env(safe-area-inset-top)' }}
      className="relative flex h-full w-full flex-col overflow-y-auto overscroll-y-contain bg-[#5A23BA] pb-40"
    >
      {isStartPackageDialogVisible && (
        <StartSubPackageDialog
          subPackageId={subPackageId}
          isDialogVisible={isStartPackageDialogVisible}
          onClose={closeStartPackageDialog}
        />
      )}

      <MainHeader
        title={t('title')}
        isSwitchClassEnable
        isSwitchClassBannerVisible={isSwitchClassBannerVisible}
        toggleSwitchClassBanner={toggleSwitchClassBanner}
      />

      {isSwitchClassBannerVisible && (
        <div className="fixed inset-0 top-[env(safe-area-inset-top)] z-[98] bg-black/50 backdrop-blur" />
      )}

      <div className="flex h-auto w-full flex-col items-center justify-start pt-16 md:pt-20">
        <div className="mb-4 w-full px-4 md:px-8">
          <InputSearch onSearch={handleSearch} />
        </div>

        {!loading && progressPackagesFiltered.length === 0 && otherPackagesFiltered.length === 0 && (
          <NoPackageSection />
        )}

        <LoaderWrapper loading={loading} showChildrenOnLoading={!!overview}>
          {progressPackagesFiltered.length > 0 && (
            <div className="flex w-full flex-col gap-2 pl-4 md:pl-8">
              <div className="flex w-full flex-row items-center justify-start">
                <span className="text-[18px] font-bold text-white">{t('inProgress')}</span>
              </div>

              <Swiper
                className="mb-4 w-full pr-4"
                modules={[FreeMode]}
                freeMode={true}
                spaceBetween={12}
                slidesPerView="auto"
              >
                {progressPackagesFiltered.map((subP) => {
                  return (
                    <SwiperSlide key={subP?.id} className="w-auto">
                      <SubPackage data={subP} onClick={showStartPackageDialog} />
                    </SwiperSlide>
                  )
                })}
              </Swiper>
            </div>
          )}

          {otherPackagesFiltered.map((p, index) => {
            return (
              <div key={index} className="flex w-full flex-col gap-2 pl-4 md:pl-8">
                <div className="flex w-full flex-row items-center justify-start">
                  <span className="text-[18px] font-bold text-neutral50">{p.name}</span>
                </div>

                <Swiper
                  className="mb-4 w-full"
                  modules={[FreeMode]}
                  freeMode={true}
                  spaceBetween={12}
                  slidesPerView="auto"
                >
                  {p.subPackages.map((subP) => {
                    return (
                      <SwiperSlide key={subP?.id} className="w-auto">
                        <SubPackage data={subP} onClick={showStartPackageDialog} />
                      </SwiperSlide>
                    )
                  })}
                </Swiper>
              </div>
            )
          })}
        </LoaderWrapper>
      </div>
    </div>
  )
}
