import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

import i18n from '@app/locales/i18n'
import {
  AppLanguages,
  appLanguageToCode,
  languageCodeToShort,
  languageCodeToSupportedLanguage
} from '@app/locales/types'
import { localSettingsStore } from '@app/modules/common/store/localSettingsStore'
import { languageToFlagIcon } from '@app/utils/iconUtils'

export const LanguageSelector = () => {
  const { t } = useTranslation('settings')

  const appLanguage = localSettingsStore.useStore((state) => state.appLanguage)
  const [selectedLanguage, setSelectedLanguage] = useState(appLanguageToCode[appLanguage])
  const Icon = languageToFlagIcon[languageCodeToSupportedLanguage[selectedLanguage]]

  return (
    <div className="flex items-center">
      {Icon && <Icon />}
      <select
        onChange={async (e) => {
          localSettingsStore.setAppLanguage(languageCodeToSupportedLanguage[e.target.value])
          const storedAppLang = localSettingsStore.useStore.getState().appLanguage
          const appLang = storedAppLang ? appLanguageToCode[storedAppLang] : 'cs-CZ'
          dayjs.locale(languageCodeToShort[appLang] || 'cs')

          await i18n.changeLanguage(e.target.value)
          setSelectedLanguage(e.target.value as AppLanguages)
        }}
        defaultValue={selectedLanguage}
        className="rounded-md bg-[transparent] p-1 text-white focus:outline-none"
      >
        <option value={'en-US'}>{t('appLanguage.ENGLISH')}</option>
        <option value={'cs-CZ'}>{t('appLanguage.CZECH')}</option>
      </select>
    </div>
  )
}
