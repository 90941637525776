import { useRef } from 'react'

import { useTimeout } from '@foxino/components-common'

import { ImageContent } from '@app/assets/ImageContent'
import { VideoContent } from '@app/assets/VideoContent'
import { LearningAssignmentType, LearningExecutionState } from '@app/data'
import { useTranslation } from '@app/locales'
import { subjectToFlagRoundIcon } from '@app/utils/iconUtils'

import { LearningExecutionType } from '../model/types'

type Props = {
  task: LearningExecutionType
  onClick: (task: LearningExecutionType, type: string) => void
}

export const TaskCardBig = ({ task, onClick }: Props) => {
  const { t } = useTranslation('dashboard')
  const videoRef = useRef<HTMLVideoElement>(null)

  const isInProgress = task.state === LearningExecutionState.IN_PROGRESS

  const isTest = task.learningAssignment.type === LearningAssignmentType.TEST
  const isHomework = task.learningAssignment.type === LearningAssignmentType.HOMEWORK
  const isRepetition =
    task.learningAssignment.type === LearningAssignmentType.PERSONALIZED_REPETITION ||
    task.learningAssignment.type === LearningAssignmentType.REPETITION

  const [callTimeout] = useTimeout(isTest || isRepetition ? 0 : 2000)

  const videoSrc = isTest
    ? VideoContent.tasks.test
    : isHomework
      ? VideoContent.tasks.homework
      : isRepetition
        ? VideoContent.tasks.repetition
        : VideoContent.tasks.selfStudy

  const videoPosterSrc = isTest
    ? ImageContent.tasks.testVideoPoster
    : isHomework
      ? ImageContent.tasks.homeworkVideoPoster
      : isRepetition
        ? ImageContent.tasks.repetitionVideoPoster
        : ImageContent.tasks.selfStudyVideoPoster

  const contentBgSrc = isTest
    ? ImageContent.tasks.test
    : isHomework
      ? ImageContent.tasks.homework
      : isRepetition
        ? ImageContent.tasks.repetition
        : ImageContent.tasks.selfStudy

  const progressBarFgSrc = isTest
    ? ImageContent.tasks.testProgressBarFg
    : isHomework
      ? ImageContent.tasks.homeworkProgressBarFg
      : isRepetition
        ? ImageContent.tasks.repetitionProgressBarFg
        : ImageContent.tasks.selfStudyProgressBarFg

  const progressBarTopSrc = isTest
    ? ImageContent.tasks.testProgressBarTop
    : isHomework
      ? ImageContent.tasks.homeworkProgressBarTop
      : isRepetition
        ? ImageContent.tasks.repetitionProgressBarTop
        : ImageContent.tasks.selfStudyProgressBarTop

  const handleOnPlayClick = () => {
    if (isInProgress) {
      onClick(task, 'even')
    }
  }

  const handleVideoEnded = () => {
    if (videoRef.current) {
      callTimeout(() => {
        videoRef.current?.play()
      })
    }
  }

  const LanguageFlag = subjectToFlagRoundIcon[task.learningAssignment.subject]
  const progress = task.progress ? (task.progress < 5 ? 5 : task.progress) : 0
  const clipPath = `inset(0 calc(100% - ${progress}%) 0 0)`

  return (
    <div
      className="relative flex h-[364px] w-full cursor-pointer flex-col items-center justify-end overflow-hidden rounded-3xl shadow-card hover:brightness-125"
      onClick={handleOnPlayClick}
    >
      <video
        ref={videoRef}
        className="absolute left-0 top-0 z-0 h-[80%] w-full object-cover"
        poster={videoPosterSrc}
        playsInline={true}
        loop={false}
        autoPlay={true}
        muted={true}
        onEnded={handleVideoEnded}
        src={videoSrc}
      />

      {isInProgress && (
        <div className="absolute right-0 top-0 z-50 h-[60px] w-[80px] overflow-hidden">
          <img className="absolute top-0 z-50 h-full w-full" src={progressBarTopSrc} />
          <div className="absolute top-2 z-50 flex h-full w-full justify-center font-[GhostKidAOEPro] text-white0">
            {progress}%
          </div>
        </div>
      )}

      <div className="absolute bottom-0 h-[128px] w-full overflow-hidden">
        {isInProgress && (
          <div className="absolute bottom-0 top-0 z-40 h-full w-full overflow-hidden" style={{ clipPath }}>
            <img className="z-20 h-full w-full" src={progressBarFgSrc} />
          </div>
        )}

        <img className="absolute bottom-0 z-30 h-full w-full" src={contentBgSrc} />
      </div>

      <div className="relative z-40 flex h-[128px] w-full flex-row items-center justify-between p-4 pb-2 pr-2">
        <div className="flex h-full w-full flex-col justify-end gap-3 pt-4">
          <div className="w-full text-[20px] font-bold text-white0">
            {t(`learningAssignmentType.${task.learningAssignment.type.toLocaleLowerCase()}`)}
          </div>
          <div className="font-semibold text-white0">
            {task.name || t(`learningAssignmentType.${task.learningAssignment.type.toLocaleLowerCase()}`).toUpperCase()}
          </div>
        </div>

        <div className="flex h-full w-10 flex-col justify-between pt-4">
          <div className="flex-1"></div>
          <div className="h-10 w-10 overflow-hidden rounded-full">
            <LanguageFlag className="h-10 w-10" />
          </div>
        </div>
      </div>
    </div>
  )
}
