import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useToggle } from 'react-use'
import { Dialog, DialogPanel } from '@headlessui/react'
import dayjs from 'dayjs'
import { AnimatePresence, motion } from 'framer-motion'

import { ImageContent } from '@app/assets/ImageContent'
import { SvgContent } from '@app/assets/SvgContent'
import { Button, LoaderWrapper, Text } from '@app/components'
import { Pages } from '@app/config/router/Pages'
import { StudentLibraryRankLevel } from '@app/data'
import { useTranslation } from '@app/locales'
import { LessonProgressBar } from '@app/modules/lesson/components'
import { getImageUrlFromImageType } from '@app/utils/imageUtils'

import { useStudentLibraryDetail } from '../data/useStudentLibraryDetail'
import { getBgColor } from '../utils/getBgColor'
import { useFavoriteToggleHandler } from '../utils/useFavoriteToggleHandler'
import { useStartPackageHandler } from '../utils/useStartPackageHandler'
import { LevelSwitch } from './LevelSwitch'

type Props = {
  subPackageId: unknown
  isDialogVisible: boolean
  onClose: () => void
}

export const StartSubPackageDialog = ({ subPackageId, isDialogVisible, onClose }: Props) => {
  const navigate = useNavigate()
  const { t } = useTranslation('library')

  const typeId = subPackageId as string

  const {
    detail: currentDetail,
    error: detailError,
    loading: detailLoading,
    refetch: refetchDetail
  } = useStudentLibraryDetail({ skip: typeId == null, variables: { subPackageId: typeId } })

  const [isFavoriteFlag, toggleIsFavoriteFlag] = useToggle(currentDetail?.isFavorite || false)

  useEffect(() => {
    if (currentDetail) {
      toggleIsFavoriteFlag(currentDetail?.isFavorite)
    }
  }, [currentDetail])

  useEffect(() => {
    refetchDetail()
  }, [isDialogVisible])

  // get the highest enabled level
  const currentLevel =
    currentDetail?.levels.find((lvl) => lvl.level === StudentLibraryRankLevel.LEGEND && lvl.isEnabled) ??
    currentDetail?.levels.find((lvl) => lvl.level === StudentLibraryRankLevel.PRO && lvl.isEnabled) ??
    currentDetail?.levels.find((lvl) => lvl.level === StudentLibraryRankLevel.ROOKIE)

  const handleShowDetail = () => {
    navigate(Pages.DASHBOARD_PACKAGE_DETAIL(typeId))
  }

  const { handleStartPackage } = useStartPackageHandler({ id: typeId, currentDetail, currentLevel })
  const { handleOnFavoriteClick } = useFavoriteToggleHandler({ id: typeId, isFavoriteFlag, toggleIsFavoriteFlag })

  if (!typeId || !currentLevel || currentDetail == null) {
    return null
  }

  const getTimeDescription = () => {
    if (!currentLevel?.isStarted && currentLevel?.progress == null) {
      return t('duration') + dayjs.duration(currentLevel?.estimatedDurationSeconds || 0, 'seconds').humanize()
    } else if (currentLevel?.progress && currentLevel?.progress < 100) {
      return t('remains') + dayjs.duration(currentLevel?.remainingDurationSeconds || 0, 'seconds').humanize()
    } else {
      return t('completedIn') + dayjs.duration(currentLevel?.finishedDurationSeconds || 0, 'seconds').humanize()
    }
  }

  const imageUrl = getImageUrlFromImageType(currentDetail?.image)

  return (
    <AnimatePresence>
      {isDialogVisible && (
        <Dialog static open={isDialogVisible} onClose={onClose} className="relative z-[1001] w-full overflow-hidden">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black/50 backdrop-blur"
          />
          <div className="fixed inset-0 flex w-screen items-center justify-center px-2">
            <DialogPanel
              as={motion.div}
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.9 }}
              className="relative w-full max-w-[360px] overflow-hidden rounded-3xl"
            >
              <div className="relative flex h-[384px] w-full flex-col">
                <LoaderWrapper
                  loading={detailLoading}
                  showChildrenOnLoading={false}
                  error={!!detailError}
                  withoutErrorImage={true}
                >
                  <div
                    style={{ background: getBgColor(currentDetail?.imageBackground) }}
                    className="relative h-1/2 w-full"
                  >
                    <img src={imageUrl} className="z-10 h-full w-full object-cover" />
                  </div>

                  <div
                    style={{
                      boxShadow:
                        '0px -80px 90px 25px rgba(44, 0, 119, 0.80), 0px 1px 1px 0px rgba(255, 255, 255, 0.25) inset'
                    }}
                    className="relative h-full w-full bg-main-bg-gradient"
                  >
                    <div className="absolute -top-[120px] z-40 flex w-full flex-col items-center justify-center">
                      <div className="line-clamp-1 text-center text-[32px] font-extrabold text-[#FAFAFA]">
                        {currentDetail?.name}
                      </div>
                      <div className="flex flex-row items-center justify-between gap-1 font-[600] text-[#FAFAFA]">
                        <object
                          className="h-6 w-6 object-contain"
                          type="image/svg+xml"
                          data={SvgContent.library.clockFilled}
                        />
                        {getTimeDescription()}
                      </div>

                      <div className="mt-3 w-full max-w-[360px]">
                        <LevelSwitch readonly={true} level={currentLevel.level} />
                      </div>
                    </div>

                    <div className="flex h-full w-full flex-col justify-between py-4">
                      <div className="flex w-full flex-col">
                        <div className="flex w-full flex-row items-center justify-between gap-2 px-4 pb-0">
                          {/* descriptions */}
                          <div className="flex w-full flex-col gap-2">
                            <div className="flex flex-row items-center gap-2 font-[600] text-[#FAFAFA]">
                              <object
                                className="h-6 w-6 object-contain"
                                type="image/svg+xml"
                                data={SvgContent.library.bookmark}
                              />
                              {currentDetail?.packageName}
                            </div>
                            <div className="flex flex-row items-center gap-2 font-[600] text-[#FAFAFA]">
                              <div className="flex h-6 w-6 items-center justify-center">
                                <object
                                  className="h-5 w-5 object-center"
                                  type="image/svg+xml"
                                  data={SvgContent.library.chatBuble}
                                />
                              </div>
                              {currentLevel.category.map((item) => t(`category.${item.toString()}`)).join(', ')}
                            </div>
                          </div>

                          {/* Favorite icon */}
                          <div
                            style={{
                              backgroundImage: `url(${ImageContent.library.favoriteEclipse})`,
                              backgroundSize: 'contain',
                              backgroundRepeat: 'no-repeat',
                              backgroundPosition: 'center'
                            }}
                            className="flex h-14 w-14 cursor-pointer items-center justify-center"
                            onClick={handleOnFavoriteClick}
                          >
                            <motion.img
                              className="h-10 w-10 rounded-full"
                              src={
                                isFavoriteFlag
                                  ? ImageContent.library.favoriteHeart
                                  : ImageContent.library.favoriteInactive
                              }
                              alt="favoriteEclipse"
                              initial={{ scale: 0.8, opacity: 0 }}
                              animate={{
                                scale: isFavoriteFlag ? 1.2 : 1,
                                opacity: isFavoriteFlag ? 1 : 0.8
                              }}
                              transition={{ type: 'spring', stiffness: 300, damping: 15 }}
                            />
                          </div>
                        </div>

                        <div className="flex h-[48px] w-full items-center justify-start gap-4 px-4 pr-6">
                          <LessonProgressBar
                            progress={currentLevel.progress || 0}
                            hideCloudAnimations={true}
                            offsetTop={0}
                            offsetLeft={25}
                          />
                          <Text className="font-semibold text-white">{currentLevel.progress || 0}%</Text>
                        </div>
                      </div>

                      <div className="flex w-full flex-row items-center justify-between gap-4 px-4">
                        <Button className="w-full" onClick={handleShowDetail}>
                          {t('detail')}
                        </Button>
                        <Button className="w-full" mode="secondary" onClick={handleStartPackage}>
                          {currentLevel?.progress == null
                            ? t('start')
                            : currentLevel?.progress === 100
                              ? t('startAgain')
                              : t('continue')}
                        </Button>
                      </div>
                    </div>
                  </div>
                </LoaderWrapper>
              </div>
            </DialogPanel>
          </div>
        </Dialog>
      )}
    </AnimatePresence>
  )
}
